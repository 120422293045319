export enum DeliveryType {
  Online = 1,
  Scorm = 2,
  FaceToFaceAttendance = 3,
  FaceToFaceAssessment = 4,
}

export enum CourseExamSubmissionType {
  MultipleSubmissionsUntilAllCorrect = 1,
  OneChanceOnly = 2,
}