











import AppVue from "@/apps/AppVue.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import router from "../../router/router";

@Component
export default class AuthStatus extends AppVue {
  protected userName?: string = "";

  public isAuthenticated() {
    return this.$msal.isAuthenticated;
  }

  public logout() {
    if (this.$store) {
      this.$store.dispatch("clearUserRoles");
    }

    this.$msal.logout();
    this.$root.$emit("USER_UPDATED");

    this.$notify({
      title: "Success",
      message: "You are now logged out.",
      type: "success",
    });

    router.push("/login");
  }

  public getAccountName() {
    try {
      const account = this.$msal.getLoggedInAccount();

      return account ? account.name : "";
    } catch (err) {
      return "";
    }
  }

  /*
  @Watch("$msal", { deep: true })
  protected updateAccount() {
    console.log("Updating MSAL");
  }
  */

}
