import {
  CourseModel,
  CourseCollectionModel,
  SimpleLookupModel,
  LicenseModel,
  AssessmentModel,
  Validatable,
  UserModel,
} from "@/core/models";

export class CourseSessionModel extends Validatable {
  courseSessionId: number;
  courseId: number | undefined;
  course: CourseModel | undefined;
  licenseId: number | undefined;
  license: LicenseModel | undefined;
  startDate: Date;
  startTime: string | undefined;
  endDate: Date;
  unionId: number | undefined;
  educatorUserId: number | undefined;
  localeId: number | undefined;
  locale: string | undefined;
  capacity: number;
  venue: string;
  location: string;
  venueAddress: string;
  notes: string;
  courseSessionStatusId: number;
  isApprovable: boolean;
  areDetailsEditable: boolean;
  isSubjectEditable: boolean;
  areEducatorsEditable: boolean;
  canAttendeesBeAdded: boolean;
  disableAttendeeInvite: boolean;
  attendees: CourseSessionAttendeeCollectionModel[];
  educators: CourseSessionEducatorModel[];
  attendeeCount: number;
}

export class CourseSessionHostFormLookupModel {
  courses: CourseCollectionModel[];
  unions: SimpleLookupModel[];
  locales: SimpleLookupModel[];
}

export class CourseOwnSessionGridLookupModel {
  statuses: SimpleLookupModel[];
  assessmentStatuses: SimpleLookupModel[];
}

export class CourseScheduledSessionGridLookupModel {
  regions: SimpleLookupModel[];
  unions: SimpleLookupModel[];
  strands: SimpleLookupModel[];
  statuses: SimpleLookupModel[];
  assessmentStatuses: SimpleLookupModel[];
  levels: SimpleLookupModel[];
}

export class CourseSessionCollectionModel {
  courseSessionId: number;
  course: string;
  startDate: Date;
  endDate: Date;
  union: string;
  venue: string;
  status: string;
  level: string;
  venueAddress: string;
  attendeeNotes: string;
}

export class CourseSessionSummaryModel extends CourseSessionCollectionModel {
}

export class CourseSessionLookupModel extends SimpleLookupModel {
  startDate: Date;
}

export class CourseSessionAttendeeCollectionModel {
  courseSessionAttendeeId: number;
  courseSessionId: number;
  userId: number;
  userFullName: string;
  statusId: number;
  statusName: string;
  unionId: number;
  unionName: string;
  unmetPrerequisites: string[];
  assessment: AssessmentModel;
  isStatusUpdateAllowed: boolean;
}

export class CourseSessionAttendanceSetStatusModel {
  courseSessionId: number;
  statusId: number;
  attendeeIds: number[];
}

export class CourseSessionEducatorModel {
  userId: number;
  user: string;
  licenseId: number;
  isOrganiser: boolean;
  isSecondaryEducator: boolean;
}

export class EligibleEducatorsModel {
  educators: CourseSessionEducatorModel[] = [];
  secondaryEducators: CourseSessionEducatorModel[] = [];
}

export class CourseSessionAttendeeUpdateList {
  courseSessionId: number;
  attendeeUserids: number[];
  sendAttendeeInvite: boolean = false;
}

export class UserCourseSessionCollectionModel {
  courseSessionId: number;
  startDate: number;
  union: string;
  venue: string;
}

export class UserCourseSessionModel {
  userId: number;
  course: CourseModel;
  courseSession: UserCourseSessionCollectionModel;
}

export enum CourseSessionStatus {
  AwaitingApproval = 2,
  RequestCertificateApproval = 1004,
}