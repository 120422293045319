import {
  Validatable,
  SimpleLookupModel,
 } from "@/core/models";

export class UserModel extends Validatable {
  userId: number;
  firstName: string = "";
  lastName: string = "";
  fullName: string = "";
  email: string = "";
  unionId: number|undefined = undefined;
  unionIdToAdminister: number|undefined = undefined;
  userBaseId: number|undefined = undefined;
  unionName: string = "";
  lastLoggedInDate: Date|undefined = undefined;
  lastBackendLoggedInDate: Date|undefined = undefined;
}

export class UserCredentialsModel extends Validatable {
  email: string = "";
  password: string = "";
}

export class PassportCredentialsModel extends Validatable {
  userId: string | Array<string | null> = "";
  alphaId: string | Array<string | null> = "";
}

export class UserLookupModel {
  unions: SimpleLookupModel[];
  regions: SimpleLookupModel[];
}

export class UserRoleModel {
  name: string = "";
  description: string = "";
}

export class UpdateUserRolesModel extends Validatable {
  userRoles: string[] = [];
  unionIdToAdminister: number | undefined = undefined;
}

export class UserInviteModel extends Validatable {
  email: string = "";
}

export class UpdateUserEmailModel extends Validatable {
  email: string = "";
  verifyEmail: string = "";
}

export class MergeUserModel extends Validatable {
  mergeIntoUserId: number|null = null;
}

export class UserInfoModel {
  userId: number;
  objectId: string = "";
  userName: string = "";
  email: string = "";
  isAuthenticated: boolean = false;
  unionId: number;
  roles: string[] = [];
  acceptEducatorCodeOfConductDate: Date | null;
  canPerformQualityAudits: boolean = false;
}

export class UserTermsAndConditionsModel extends Validatable {
  acceptEducatorCodeOfConduct: boolean = false;
}

export class AddUserModel extends UserModel {
  hasEmailAddress: boolean = true;
}

export class CheckMultiUserModel extends Validatable {
  users: string = "";
}

export class CheckMultiUserResponseModel {
  prospectiveUsers: UserModel[] = [];
  nonUserEmails: string[] = [];
  emailsNotPermittedToAccess: string[] = [];
}

export class DeleteUserStatusModel {
  isEducator: boolean = false;
  hasActiveLicense: boolean = false;
  isAttendee: boolean = false;
  user: UserModel = new UserModel();
}