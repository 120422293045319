import { HttpService,  DateService } from "./common/";
import {
  LicenseModel,
  ExpireLicenseModel,
  RevokeLicenseModel,
  LicenseLookupModel,
  RevokeLicenseLookupModel,
  LicenseCollectionModel,
  PagedCollectionFilter,
  PagedCollection,
  CourseSessionModel,
  EligibleEducatorsModel,
  CourseCollectionModel,
  EducatorsMassEmailModel,
} from "@/core/models";

export const LicenseService = {
  getLicense(id: any) {
    return HttpService.getData<LicenseModel>(`/licenses/${id}`);
  },

  getLicenseCollection(id: any) {
    return HttpService.getData<LicenseCollectionModel>(`/licenses/${id}/collection`);
  },

  getActiveLicenses(date: Date, activeOnly: boolean) {
    return HttpService.getData<LicenseCollectionModel[]>(`/licenses/active?date=${DateService.formatDate(date)}&activeOnly=${activeOnly}`);
  },

  postLicense(model: LicenseModel) {
    return HttpService.postModel("/licenses", model);
  },

  filterLicenses(filter: PagedCollectionFilter) {
    return HttpService.getData<PagedCollection<LicenseCollectionModel>>("/licenses/filter", { params: filter });
  },

  getFormLookupData(forUserId?: number) {
    const config = forUserId ? { params: { forUserId } } : undefined;
    return HttpService.getData<LicenseLookupModel>("/licenses/formLookupData", config);
  },

  revokeLicense(model: RevokeLicenseModel) {
    return HttpService.putModel("/licenses/revoke", model);
  },

  expireLicense(model: ExpireLicenseModel) {
    return HttpService.putModel("/licenses/expire", model);
  },

  amendLicense(model: LicenseModel) {
    return HttpService.putModel("/licenses/amend", model);
  },

  getRevokeFormLookupData(licenseId: number) {
    const config = { params: { licenseId } };
    return HttpService.getData<RevokeLicenseLookupModel>("/licenses/revokeFormLookupData", config);
  },

  getEligibleEducators(courseSession: CourseSessionModel) {
    if (!courseSession || !courseSession.courseId || !courseSession.startDate || !courseSession.unionId) {
      return new EligibleEducatorsModel();
    }

    return HttpService.getData<EligibleEducatorsModel>(
      `/licenses/eligibleEducators?courseId=${courseSession.courseId}`
      + `&date=${DateService.formatDate(courseSession.startDate)}`
      + `&unionId=${courseSession.unionId}`);
  },

  getEligibleCourses(strandId: number, levelId: number) {
    return HttpService.getData<CourseCollectionModel[]>(
      `/licenses/eligibleCoursesByDetails?strandId=${strandId}`
        + `&LevelId=${levelId}`);
  },

  countEducatorsEmail(filter: PagedCollectionFilter) {
    return HttpService.getData<number>("/licenses/count/educators/email", { params: filter });
  },

  scheduleEducatorsEmail(model: EducatorsMassEmailModel) {
    return HttpService.postModel("/licenses/educators/email", model);
  },
};
