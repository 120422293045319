





















import { Component, Prop } from "vue-property-decorator";

import { ValidationMessage, BaseInputComponent } from ".";
import LabelWithTooltip from "./LabelWithTooltip.vue";
import { Validatable } from "@/core/models";

@Component({
  components: {
    ValidationMessage,
    LabelWithTooltip,
  },
})
export default class InputDate extends BaseInputComponent {
  @Prop({ default: "yyyy-MM-dd" }) format: string;
  @Prop({ default: "yyyy-MM-dd" }) valueFormat: string;
  @Prop() pickerOptions?: any;
}
