export default {
  "common": {
    "name": "Nume",
    "hello": "Bună ziua!",
    "details": "Detalii",
    "created": "Creat",
    "ok": "OK",
    "cancel": "Anulare",
    "save": "Salvare",
    "unpublish": "",
    "id": "ID",
    "search": "Căutare",
    "status": "Stare",
    "view": "Vizualizare",
    "course": "Curs",
    "union": "Federație",
    "noData": "Nu există date",
    "startDate": "Data începerii",
    "startTime": "",
    "endDate": "Data de sfârșit",
    "notes": "Note",
    "educators": "Instructori",
    "forUser": "Pentru utilizator",
    "regions": "Regiuni",
    "unions": "Federații",
    "existingRegions": "",
    "existingUnions": "",
    "homeUnion": "",
    "expiresOn": "Expiră la",
    "selectUser": "Selectați Utilizator",
    "none": "Fără",
    "level": "Nivelul",
    "actionAddAll": "Adăugare toate",
    "reason": "Motiv",
    "gettingData": "Se obțin date",
    "success": "Succes",
    "error": "Eroare",
    "actionEdit": "Editare",
    "actionSubmit": "Trimite",
    "logo": "Siglă",
    "actionDropFile": "Fixează fișierul aici sau",
    "imageFormatsAllowed1": "Sunt permise fișiere JPG/PNG/GIF",
    "forename": "Prenume",
    "lastname": "Nume",
    "emailAddress": "Adresă de e-mail",
    "email": "Emailul",
    "validationIsRequired": "este necesar",
    "code": "Cod",
    "certified": "Certificat",
    "certExpiry": "Expirare certif.",
    "session": "Ședință",
    "yes": "Da",
    "no": "Nu",
    "actionDownload": "Descărcare",
    "close": "Închidere",
    "user": "Utilizator",
    "location": "",
    "from": "",
    "to": "",
    "type": "",
    "validationError": "",
    "validationErrorDescription": "",
    "errorSaving": "",
    "saving": ""
  },
  "navigation": {
    "courses": "Cursuri",
    "myCourses": "Cursurile mele",
    "courseAdmin": "Denumire curs",
    "training": "Antrenament",
    "mySessions": "Ședințele mele",
    "sessionAdmin": "Administrator ședință",
    "dashboard": "Tablou de bord",
    "navAbout": "Despre",
    "navAdministration": "Administrații",
    "navUnions": "Federații",
    "logout": "Deconectare",
    "myProfile": "Profilul meu",
    "coursesAttended": "Cursuri la care ați participat",
    "licensesAwarded": "Licențe acordate",
    "certification": "",
    "fileshare": "",
    "correspondence": ""
  },
  "mySessions": {
    "viewMyTrainingSession": "Vizualizare sesiunile mele de antrenament",
    "hostTrainingSession": "Găzduire sesiune de antrenament",
    "selectCourse": "Vă rugăm să selectați cursul",
    "selectUnion": "Vă rugăm să selectați federația",
    "selectLicense": "Vă rugăm să selectați licența",
    "licenseInfo": "Informații despre licență",
    "validIn": "Valid în",
    "language": "Limbă",
    "unionToAdmin": "Federație de administrat"
  },
  "filters": {
    "filterByRegion": "Filtrare după regiune",
    "filterByUnion": "Filtrare după federație",
    "filterByStatus": "Filtrare după statut",
    "filterByQualityStatus": "Filtrare după statut calitate",
    "filterByLevel": "Filtrare după nivel",
    "filterByStrand": "Filtrare după tematică",
    "filterByExpiry": "Filtrare după data expirării",
    "filterByDeliveryType": "Filtrare după tipul de efectuare",
    "pleaseSelectLevel": "Vă rugăm să selectați nivelul",
    "pleaseSelectStrand": "Vă rugăm să selectați poziția",
    "pleaseSelectRegions": "Vă rugăm să selectați regiunile",
    "pleaseSelectUnions": "Vă rugăm să selectați federațiile",
    "pleaseSelectStatus": "Vă rugăm să selectați statutul",
    "filterByLanguage": "",
    "filterByCourse": "",
    "filterByAttendeeRegion": "",
    "filterByAttendeeUnion": "",
    "filterByUserEmail": "",
    "filterByAssessmentStatus": ""
  },
  "sessionAdmin": {
    "viewScheduledTrainingSession": "Vizualizare sesiuni de antrenament programate",
    "scheduleTrainingSession": "Programare sesiune de antrenament",
    "addTrainingSession": "Adăugare sesiune de antrenament"
  },
  "editTrainingSession": {
    "editTrainingSession": "Editare sesiune de antrenament",
    "scheduledTraining": "Sesiune de antrenament programată",
    "certificateLanguage": "Certificat cunoștințe limbă",
    "venueName": "Denumire locație",
    "venueAddress": "Adresă locație",
    "organiser": "Organizator",
    "selectAnEducator": "Selectați un instructor",
    "addEducator": "Adăugați un instructor",
    "addNoteToHistory": "Adăugare notă la istoric",
    "approve": "Acceptare",
    "saveTrainingSession": "Salvare sesiune de antrenament",
    "viewTrainingSessionHistory": "Vizualizare istoric sesiuni de antrenament",
    "noHistory": "Nu există istoric de afișat",
    "addEducatorWarning": "Acest instructor poate asista, dar nu poate conduce ședința. Fiecare ședință necesită cel puțin un instructor-șef.",
    "assistingEducators": "Instructori asistenți",
    "canLead": "Pot conduce?",
    "selectLanguage": "Vă rugăm să selectați limba",
    "scheduleCourse": "Programare curs",
    "saveCourse": "Salvare curs",
    "addCourse": "Adăugare curs",
    "emailLanguageTooltip": "Mesajele email trimise participanților sunt scrise în această limbă",
    "startTimeLocalTime": ""
  },
  "addNote": {
    "addNote": "Adăugare notă",
    "noteDetails": "Detalii notă"
  },
  "courseSessionStatus": {
    "awaitingApproval": "În curs de aprobare",
    "rejected": "Respins",
    "approved": "Aprobat"
  },
  "tabs": {
    "tabattendees": "Participanți",
    "tabhistory": "Istoric",
    "tabCourseMaterials": "Materiale de curs"
  },
  "addCourseAttendees": {
    "titleViewTrainingSessionAttendees": "Vizualizare participanți la sesiunile de antrenament",
    "editAttendeeList": "Editare listă participanți",
    "attendeeName": "Nume",
    "assessmentStatus": "Stare evaluare",
    "actions": "Acțiuni",
    "editAttendees": "Editare participanți",
    "actionConfirm": "Confirmare",
    "noUsersFound": "Nu s-au găsit utilizatori",
    "attendeeUpdateError": "Eroare de actualizare participanți",
    "attendeeUpdateErrorDescription": "S-a produs o eroare la actualizarea listei participanților. A fost configurat un șablon email pentru ședință la nivel local?",
    "optionSelectStatus": "Selectare stare",
    "actionUpdateStatus": "Actualizare stare",
    "actionAdd": "Adăugare",
    "titleAddAssessment": "Adăugare evaluare",
    "selectLanguage": "selectați Limba",
    "actionLicense": "Licență",
    "actionLicenseDescription": "Acordați o licență acestui participant",
    "actionEdit": "Editare",
    "unmetPrerequisites": "Condiții preliminare neîndeplinite",
    "approveAreYouSure": "Sunteți sigur că doriți să aprobați certificarea participanților selectați",
    "approveCertification": "Aprobare certificare",
    "errorUpdatingStatusTitle": "Eroare actualizare statut",
    "errorUpdatingStatusMessage": "S-a produs o eroare la actualizarea statutului participanților",
    "sendAttendeeInvites": "",
    "sendAttendeeInvitesToolTip": ""
  },
  "editAssessment": {
    "editAssessment": "Editare evaluare",
    "competency": "Competență",
    "notYetCompetent": "Încă nu este competent",
    "notCompetent": "",
    "competent": "Competent",
    "excellent": "Excelent",
    "criteria": "Criterii",
    "commentHelpText": "Comentariu - Obligatoriu pentru „Încă nu este competent”",
    "actionSaveAndPublish": "Salvare și publicare",
    "assessorComment": "Comentariu evaluator",
    "publishedBy": "Publicat de",
    "lastUpdatedBy": "Actualizat ultima oară de",
    "addedBy": "Adăugat de",
    "errorSavingAssessment": "Eroare la salvarea evaluării",
    "errorSavingAssessmentMessage": "Verificați dacă toate intrările au fost valide",
    "confirmCancelAssessment": "Sunteți sigur că doriți să anulați?",
    "leaveAssessment": "Încheiați evaluarea?",
    "confirmPublishAssessment": "Sunteți sigur că doriți să publicați evaluarea aceasta? După publicare, evaluarea nu poate fi editată",
    "publishAssessment": "Publicați evaluarea?",
    "viewAssessment": "Vizualizare evaluare",
    "attendeeSelfReflection": "Autoreflecție participant",
    "postCourseProgress": "",
    "postLiveExperienceProgress": "",
    "attendeeNotCompetentHeading": "",
    "attendeeMayBeCompetent": "",
    "attendeeNotCompetent": "",
    "attendeeNotCompetentConfirmation": "",
    "unpublishAssessmentConfirmation": ""
  },
  "feedback": {
    "viewFeedback": "Vizualizare feedback"
  },
  "activeCerts": {
    "activeCertificateStatistics": "Statistici certificat activ",
    "date1": "Data 1",
    "date2": "Data 2",
    "activeCerts": "Cert. active",
    "pickDate": "Selectați o dată",
    "total": "Total"
  },
  "issuedCerts": {
    "issuedCertificateStatistics": "Statistică certificat emis",
    "filterByDeliveryType": "Filtrare după tipul de efectuare",
    "range1": "Interval 1",
    "range2": "Interval 2",
    "dateTo": "Pentru",
    "regionUserFilters": "Filtre regiune/utilizator",
    "regionUserFiltersDescription1": "Filtrele pentru regiune și federație se aplică diferit, în funcție de tipul de furnizare a cursului:",
    "regionUserFiltersDescription2": "Online & SCORM: Statisticile sunt grupate de federația înregistrată pentru participant",
    "regionUserFiltersDescription3": "Față în față: Statisticile sunt grupate de federația în cadrul căreia a avut loc antrenamentul",
    "courseCode": "Cod curs",
    "courseName": "Denumire curs",
    "issuedCerts1": "Cert. emise 1",
    "issuedCerts2": "Cert. emise 2",
    "percentageIncrease": "Creștere în %",
    "range1Start": "Interval 1 Început",
    "range1End": "Interval 1 sfârșit",
    "range2Start": "Interval 2 început",
    "range2End": "Interval 2 sfârșit"
  },
  "strands": {
    "strandRugbyReady": "Rugby ready",
    "strandLaws": "Regulamentul de joc",
    "strandStrengthAndConditioning": "Strength and Conditioning (Program de pregătire fizică)",
    "strandPlayerWelfare": "Bunăstarea jucătorilor",
    "strandKeepRugbyClean": "Păstrați rugbiul curat",
    "strandCoaching": "Antrenament",
    "strandOfficiating": "Oficiere",
    "strandIntegrity": "Integritate",
    "strandMatchDayStaff": "Personalul din ziua meciului",
    "strand": "Tematica"
  },
  "licenseStatus": {
    "licenseActive": "Activă",
    "licenseRevoked": "Revocat",
    "licenseExpired": "Expirat",
    "licenseProvisional": "Provizoriu"
  },
  "licenses": {
    "licenses": "Licențe",
    "grantEducatorLicense": "Acordare licență pentru instructor",
    "actionRevoke": "Revocare",
    "grantALicense": "Acordați o licență",
    "grantLicense": "Acordare licență",
    "certificatesAwarded": "Certificate acordate",
    "actionGrantALicense": "Acordați o licență",
    "licenseRevoke": "Revocare licență",
    "revokeLicense": "Revocați licența",
    "noRevokeEmailHeader": "Nu se trimite automat email de revocare",
    "noRevokeEmailBody": "Nu este trimis un email automat atunci când o licență este revocată. Administratorii pot să comunice această acțiune personal",
    "viewEligibleCourses": "Vizualizare cursuri eligibile",
    "eligibleCourses": "Cursuri eligibile",
    "messageLicenceAwardDelay": "Există o întârziere între un manager care acordă un certificat unui participant și certificatul care este emis participantului. Tabelul următor prezintă numai certificate acordate, astfel că este posibil ca un certificat acordat recent unui participant să nu apară în tabelul de mai jos",
    "messageEducatorCanTeach": "Un instructor cu licența propusă poate preda următoarele cursuri",
    "licenseAwardedTo": "Licență acordată",
    "errorAddingLicense": "S-a produs o eroare la adăugarea acestei licențe",
    "gettingLicenseInformation": "Se obțin informații despre licență",
    "noLicenses": "Nu aveți licențe active în prezent",
    "licenseHistory": "Istoric licență",
    "qualityStatus": "Statut calitate",
    "delayIssuingCertificate": "Notă: Întârziere în emiterea certificatelor",
    "delayIssuingCertificateInfo": "Există o întârziere între un manager care acordă un certificat unui participant și certificatul care este emis participantului. Tabelul următor prezintă numai certificate acordate, astfel că este posibil ca un certificat acordat recent unui participant să nu apară în tabelul de mai jos",
    "noActiveLicenses": "Nu există licențe active",
    "addLicenseError": "S-a produs o eroare la adăugarea acestei licențe",
    "myLicenses": "Licențele mele",
    "emailWorkforce": "Email workforce",
    "licenseAmended": "",
    "licenseAmendedConfirmationHeading": "",
    "licenseAmendConfirmation": "",
    "expireLicense": "",
    "amendLicense": ""
  },
  "regionAdmin": {
    "rtmUser": "Utilizator RTMP",
    "saveRegion": "Salvare regiune"
  },
  "unionAdmin": {
    "unionTrainingEducationAdministrator": "Administrator T&E al Federației",
    "canDeliverCoursesForWr": "Poate preda cursuri în numele World Rugby",
    "saveUnion": "Salvare Federație",
    "gettingUnion": "Se obține federația",
    "unionSaved": "Federație salvată"
  },
  "certStatus": {
    "certStatusInProgress": "În desfășurare",
    "certStatusValid": "valid",
    "certStatusInExpired": "Expirat",
    "certStatusAll": "Toate"
  },
  "userAdmin": {
    "userList": "Lista utilizatorilor",
    "inviteUser": "Invită utilizator",
    "infoNoEmailInviteHeader": "Nu va fi trimis un email de invitare",
    "infoNoEmailInviteBody1": "Această funcție adaugă un utilizator la baza de date cu Rol Iris backend",
    "infoNoEmailInviteBody2": "Nu se trimite email de invitare utilizatorului ca parte a acestei caracteristici, astfel că este responsabilitatea administratorilor Iris de a-i înștiința că au fost adăugați",
    "infoNoEmailInviteBody3": "Dacă utilizatorul nu a făcut acest lucru, utilizatorul ar trebui să se înregistreze la WR SSO folosind adresa de email furnizată aici",
    "actionAddUserAndAssignBackendRole": " Adăugare utilizator și alocare rol backend",
    "irisUserAddedHeader": "Utilizator Iris backend adăugat",
    "irisUserAddedBody": "a fost adăugat ca utilizator Iris backend",
    "errorAddingUserHeader": "Eroare la adăugarea utilizatorului backend",
    "errorAddingUserBody": "S-a produs o eroare la adăugarea utilizatorului backend",
    "isExactEmail": "Este emailul exact",
    "userBaseId": "ID Bază utilizatori",
    "lastLoginDate": "Data ultimei conectări",
    "coursesAndCertificates": "Cursuri și certificate",
    "roles": "Roluri",
    "currentValidCertificates": "Certificate valide în prezent",
    "userLicenses": "Licențe utilizatori",
    "userNeverGrantedLicense": "Acestui utilizator nu i s-a acordat nicio licență niciodată",
    "infoRegionManagerheader": "Rol de manager regional de formare profesională",
    "infoRegionManagerDetail1": "Rolul de manager regional de formare profesională poate fi acordat",
    "infoRegionManagerDetail2": "în formularul administrației regionale",
    "infoUnionManagerheader": "Rol de manager federal de formare profesională",
    "infoUnionManagerDetail1": "Rolul de manager federal de formare profesională poate fi acordat",
    "infoUnionManagerDetail2": "în formularul administrației federale",
    "userRoles": "roluri utilizator",
    "infoRolesUpdatedHeader": "Roluri actualizate",
    "infoRolesUpdatedBody": "Rolurile utilizatorilor au fost actualizate cu succes",
    "userExactEmail": "Utilizare email exact",
    "select": "Selectare",
    "noUsers": "Nu s-au găsit utilizatori",
    "searchAttendee": "Căutați participanți de adăugat"
  },
  "reports": {
    "reports": "Rapoarte",
    "report": "Raport",
    "deliveryType": "Tip de efectuare",
    "actionGetReport": "Obținere Raport",
    "regions": "Regiune(i)",
    "unions": "Federație(i)"
  },
  "qualityAudit": {
    "manage": "Administrare",
    "qualityAssurance": "Asigurarea calității",
    "qualityAudits": "Audituri ale calității",
    "date": "Data",
    "addQualityAudit": "Adăugare audit al calității",
    "noAuditsAdded": "Nu au fost adăugate audituri la această licență",
    "educatorCanTeach": "Un instructor cu licența propusă poate preda următoarele cursuri:",
    "auditor": "Auditor",
    "published": "Publicat",
    "actionPublish": "Publicare",
    "audits": "Audituri",
    "assignAuditor": "Desemnare auditor",
    "myAssignedQas": "Auditurile mele de calitate alocate",
    "audit": "Audit",
    "auditBy": "Audit efectuat de",
    "publishedBy": "Acest audit a fost publicat de ",
    "onDate": "la",
    "venue": "Locația",
    "detailsPlaceholder": "",
    "areasOfStrength": "Puncte forte",
    "areasOfStrengthPlaceholder": "Adăugați puncte forte aici",
    "areasForDevelopment": "Domenii de dezvoltare",
    "areasForDevelopmentPlaceholder": "Adăugați domenii de dezvoltare aici",
    "agreedActionPlan": "Plan de acțiune convenit",
    "agreedActionPlanPlaceholder": "Adăugați plan de acțiune convenit aici",
    "educatorSelfReflection": "Autoreflecție instructor",
    "educatorSelfReflectionPlaceholder": "Adăugați autoreflecție instructor aici",
    "grade": "Nivel",
    "gradePlaceholder": "Vă rugăm să selectați nivelul",
    "behaviour": "Comportament",
    "behaviourPlaceholder": "Vă rugăm să selectați comportamentul",
    "saveAsDraft": "Salvare ca schiță",
    "auditsOnEducator": "Audituri ale instructorului",
    "auditsByEducator": "Audituri efectuate de instructor",
    "educator": "Instructor",
    "addEducatorNote": "Adăugați notă instructor",
    "educatorNoteTooltip": "Notele instructorilor vor fi vizibile pentru instructori și managerii acestora în fila cu istoricul cursurilor",
    "qaSubmitSuccess": "Audit al calității trimis cu succes",
    "qaPublishedSuccess": "Audit al calității publicat cu succes",
    "qaConfirmPublish": "Sunteți sigur că doriți să publicați acest audit al calității? Auditul va fi doar în citire numai după ce este publicat.",
    "qaConfirmPublishTitle": "Publicați auditul?",
    "qaLoadingText": "Se obțin informații despre Auditul calității...",
    "manageAudits": "Administrare audituri",
    "myActiveLicences": "Licențele mele active",
    "editQualityAudit": "Editare Audit al calității",
    "submitForm": "Trimitere formular",
    "auditPublished": "Audit publicat",
    "user": "Utilizator",
    "licenseHolder": "Deținător licență",
    "licenseStatus": "",
    "publishStatus": "",
    "actionSubmitEducatorFeedback": "",
    "actionSubmitForEducatorFeedback": ""
  },
  "qualityAuditStatus": {
    "Draft": "",
    "AwaitingEducatorFeedback": "",
    "AwaitingAuditeeFeedback": "",
    "AwaitingPublish": "",
    "Published": ""
  },
  "termsAndConds": {
    "heading": "Acceptați Termenii și condițiile World Rugby",
    "main": "Pentru a trece la utilizarea Iris, trebuie să acceptați Termenii și condițiile următoare",
    "educatorCodeOfConductHeading": "Cod de conduită pentru instructor",
    "educatorLinkDescription": "Codul de conduită al instructorului poate fi vizualizat la",
    "educatorAcceptCodeOfConduct": "Accept Codul de conduită pentru instructor"
  },
  "courseSessionAttendees": {
    "removeAttendees": "Eliminare participanți",
    "addAttendees": "Adăugare participanți",
    "addAttendeesBy": "Adăugare participanți de către",
    "draft": "Schiță",
    "assessment": "Evaluare",
    "noAttendees": "Nu sunt participanți",
    "noAttendeesSubTitle": "Nu au fost adăugați participanți la acest curs. Dați clic pe butonul de mai jos pentru a adăuga participanți",
    "bulkAddAttendees": "Adăugare participanți în grup după adresa de email",
    "findExisting": "Găsire utilizator existent",
    "registeringNew": "Înregistrare utilizator nou",
    "bulkAddByEmailA": "Adăugați mai mulți participanți la acest curs. Introduceți adresa de email a participantului în câmpul de mai jos, câte o adresă de email pe rând, și dați clic pe butonul „Adăugare participanți”.",
    "bulkAddByEmailB": "Doar utilizatorii Iris existenți vor fi adăugați la curs. O listă cu adrese de email ale participanților care nu au putut fi adăugați vă va fi furnizată.",
    "addAttendeeLabel": "adrese de email ale participantului (câte una pe rând)",
    "addressesDoNotExist": "Adresele de email ale participantului nu există în Iris",
    "emailsAreRegistered": "Următoarele adrese de email sunt înregistrate ca utilizatori",
    "notPermittedToAccess": "",
    "emailsNotPermittedToAccess": "",
    "registerNewUser": "Înregistrați un utilizator nou și adăugați-l ca participant la acest curs. Faceți acest lucru doar atunci când utilizatorul nu există deja în Iris.",
    "firstName": "Nume",
    "surname": "Prenume",
    "noEmailWarning": "Dacă participantul nu are adresă de email, notificările prin email vor fi trimise instructorului organizator",
    "registerNewUserAsAttendee": "Înregistrare utilizator nou ca participant",
    "draftAttendeeList": "Schița dvs. a listei participanților",
    "updateAttendeeList": "Sunteți sigur că doriți să actualizați lista participanților?",
    "confirmThisAction": "Prin confirmarea acestei acțiuni, toți participanții nou-adăugați vor primi un email introductiv despre acest curs.",
    "confirmTitle": "Actualizați lista participanților?",
    "errorAddingUserMessage": "S-a produs o eroare la adăugarea unui utilizator",
    "errorAddingAttendees": "S-a produs o eroare la adăugarea participanților"
  },
  "courseSessionHost": {
    "attendeeNotes": "Note participanți",
    "attendeeNotesTooltip": "Aceste note vor apărea în emailurile de invitație trimise noilor participanți (după configurarea în șabloanele de email). Vă rugăm să scrieți în limba selectată",
    "addCourse": "Adăugare curs",
    "noEducatorLicensesDescription": "Nu aveți nicio licență activă. Aveți nevoie de licențe de instructor active pentru a preda cursuri. Vă rugăm să contactați Managerul regional de instruire pentru alocarea unei licențe de instructor",
    "locationTooltip": ""
  },
  "courseSessionRouteTags": {
    "viewMyCourses": "Vizualizare cursurile mele",
    "viewScheduledCourses": "Vizualizare cursuri programate",
    "hostCourse": "Găzduiți un curs",
    "scheduleCourse": "Programare curs",
    "editCourse": "Editare curs",
    "viewCourse": "Vizualizare curs",
    "viewAttendees": "Vizualizare participanți",
    "viewCourseHistory": "Vizualizare istoric curs"
  },
  "routeTags": {
    "acceptEducatorTerms": "Acceptați Termenii și condițiile instructorului"
  },
  "profileCourses": {
    "started": "Început",
    "noCoursesAttended": "Nu a participat la cursuri",
    "notAttendedAnyCourses": "Nu ați participat la niciun curs",
    "validCertLookupAll": "Toate",
    "validCertLookupCurrent": "Certificate valide în prezent",
    "validCertLookupExpired": "Expirat",
    "validCertLookupInProgress": "În desfășurare"
  },
  "profileLicenses": {
    "noLicenses": "Nu există licențe",
    "noLicensesMatch": "Nicio licență nu se potrivește cu criteriile",
    "expiry": "Data expirării"
  },
  "attendeeEmailInvite": {
    "showEmailPreview": "Arată previzualizare email de invitație",
    "attendeeEmailPreview": "Previzualizare email participant",
    "subject": "Subiect",
    "htmlContent": "Conținut HTML",
    "textContent": "Conținut text"
  },
  "myDashboard": {
    "myActions": "Acțiunile mele",
    "recentCourseRequringApproval": "Cursuri recente care necesită aprobare",
    "attendeesAwaitingCertApproval": "Participanți care așteaptă aprobarea certificării",
    "underMyAdmin": "În administrarea mea",
    "recentCourses": "Cursuri recente",
    "upcomingCourses": "Cursuri viitoare",
    "auditsInProgress": "Audituri în desfășurare",
    "myRecentCourses": "Cursurile mele recente",
    "awaitingApproval": "În curs de aprobare",
    "myUpcomingCourses": "Cursurile mele viitoare",
    "myQualityAudits": "Auditurile mele ale calității",
    "myAuditsInProgress": "Auditurile mele în desfășurare",
    "myUpcomingAudits": "Auditurile mele viitoare",
    "recentUpcomingCourses": "Cursuri recente și viitoare",
    "noCoursesSubtitle": "Nu aveți niciun curs recent și nu aveți cursuri viitoare programate. Dați clic pe butonul de mai jos pentru a găzdui un curs",
    "activeAudits": "Audituri active",
    "noAuditsSubtitle": "Nu aveți niciun audit recent și nu aveți audituri viitoare programate.",
    "auditsRequiringMyFeedback": "",
    "assessmentPeriodDueToExpire": "",
    "assessmentsExpireInPastYear": "",
    "correspondence": "Correspondence"
  },
  "fileshare": {
    "courses": "",
    "fileshareCourseMaterials": ""
  },
  "correspondence": {
    "decline": "",
    "validateCorrespondence": "",
    "id": "",
    "emailScheduleType": "",
    "status": "",
    "targetedWorkforce": "Targeted workforce",
    "viewUsers": "",
    "hideUsers": "",
    "attachments": "",
    "approve": "",
    "validate": ""
  },
  "educatorsEmail": {
    "numberOfUser": "",
    "noUsersFound": "",
    "workforce": "Workforce",
    "filters": "Filters"
  },
  "compliance": {
    "compliance": "",
    "complianceCheckEdit": "",
    "complianceCheckAdd": "",
    "compliantOnDate": "",
    "complianceCheckSuccessfullySaved": "",
    "requiredCertificates": "",
    "usersToCheck": "",
    "confirmRemoveRequiredCertificate": "",
    "confirmRemoveUser": "",
    "errorAddingUsers": "",
    "noCertificates": ""
  },
  "educatorList": {
    "numberOfCoursesDelivered": "",
    "numberOfUnionsDeliveredIn": ""
  }
};
