


































































import { Component, Prop, Watch } from "vue-property-decorator";
import _ from "lodash";

import AppVue from "@/apps/AppVue.vue";
import {
  CourseSessionModel,
  CourseSessionEducatorModel,
  EligibleEducatorsModel,
} from "@/core/models";
import {
  LicenseService,
} from "@/core/services";

@Component
export default class CourseSessionEducators extends AppVue {
  @Prop() model: CourseSessionModel;
  @Prop() sessionEducators: any = [];
  @Prop() isReadOnly: boolean;
  @Prop({ default: false }) autoAddOrganiser: boolean;

  eligibleEducators: EligibleEducatorsModel = new EligibleEducatorsModel();
  educatorIdToAdd: number | null = null;

  get courseUnionId() {
    return `${this.model.courseId}-${this.model.unionId}`;
  }

  get showEducators() {
    return this.model && this.model.startDate && this.model.courseId && this.model.unionId;
  }

  get showSecondaryEducators() {
    return this.eligibleEducators
      && this.eligibleEducators.secondaryEducators
      && this.eligibleEducators.secondaryEducators.length > 0;
  }

  async created() {
    await this.fetchEligibleEducators();
  }

  onDeleteEducator(userId: number) {
    const educatorIndex = _.findIndex(this.model.educators, {userId});
    if (educatorIndex === -1) {
      return;
    }

    this.model.educators.splice(educatorIndex, 1);
  }

  onAddEducator() {
    if (!this.educatorIdToAdd) {
      return;
    }

    this.addEducator(this.educatorIdToAdd);
    this.educatorIdToAdd = null;
  }

  addEducator(educatorId: number) {
    if (_.find(this.model.educators, {userId: educatorId }) !== undefined) {
      // Educator has already been added
      return;
    }

    if (!this.addEducatorToModel(this.eligibleEducators.educators, educatorId)) {
      this.addEducatorToModel(this.eligibleEducators.secondaryEducators, educatorId);
    }
  }

  addEducatorToModel(educators: CourseSessionEducatorModel[], userId: number) {
    const educator = _.find(educators, {userId});
    if (educator) {
      this.model.educators.push(educator as CourseSessionEducatorModel);
      return true;
    }

    return false;
  }

  @Watch("courseUnionId")
  private async initializeEligibleEducators() {
    if (this.isReadOnly) {
      return;
    }

    await this.fetchEligibleEducators();

  }

  private async fetchEligibleEducators() {
    if (this.isReadOnly) {
      return;
    }

    // Get all eligible educators
    this.eligibleEducators = await LicenseService.getEligibleEducators(this.model);
  }

}
