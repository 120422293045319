import {
  SimpleLookupModel,
  Validatable,
  UploadFileModel,
  UserModel } from "@/core/models";

import {
  CourseExamSubmissionType
} from "@/core/constants";

export class CourseModel extends Validatable {
  courseId: number ;
  name: string = "";
  code: string = "";
  createDate: Date;
  lastUpdateDate: Date;
  levelId: number;
  secondaryEducatorLevelId: number;
  strandId: number;
  deliveryTypeId: number;
  courseCertificationAuthorityId: number;
  certificateDurationInMonths: number;
  audienceId: number;
  version: string = "";
  maxNumberOfQuestionsPerPage: number | undefined = undefined;
  courseExamSubmissionTypeId: number = CourseExamSubmissionType.MultipleSubmissionsUntilAllCorrect;
  examPassMark: number | undefined = undefined;
  timeLimitInMinutes: number | undefined = undefined;
  preventResitDurationInDays: number | undefined = undefined;
  umbracoNodeId: number | undefined = undefined;
  prerequisites: CourseCollectionModel[] = [];
}

export class CourseLookupModel {
  levels: SimpleLookupModel[];
  strands: SimpleLookupModel[];
  deliveryTypes: SimpleLookupModel[];
  courseCertificationAuthorities: SimpleLookupModel[];
  audience: SimpleLookupModel[];
  courseExamSubmissionTypes: SimpleLookupModel[];
}

export class CourseCollectionModel {
  courseId: number;
  code: string;
  name: string;
  codeAndName: string;
  isActive: boolean;
  strand: string;
  level: string;
}

export class CourseCertificateModel {
  courseId: number;
  localeId: number;
  localeName: string;
  file: UploadFileModel | null;
  updatedByUser: UserModel | null;
  dateUpdated: Date;
}

export class CourseMaterialModel {
  courseMaterialId: number | null;
  courseId: number;
  localeId: number;
  locale: string;
  isActive: boolean;
  file: UploadFileModel | null;
  addedByUser: UserModel | null;
  dateAdded: Date;
  updatedByUser: UserModel | null;
  dateUpdated: Date;
}
