export default {
  "common": {
    "name": "Name",
    "hello": "Hallo",
    "details": "Details",
    "created": "Erstellt",
    "ok": "OK",
    "cancel": "Abbrechen",
    "save": "Speichern",
    "unpublish": "",
    "id": "ID",
    "search": "Suche",
    "status": "Status",
    "view": "Ansicht",
    "course": "Kurs",
    "union": "Verband",
    "noData": "Keine Daten",
    "startDate": "Startdatum",
    "startTime": "",
    "endDate": "Enddatum",
    "notes": "Hinweise",
    "educators": "Kursleiter",
    "forUser": "Für Benutzer",
    "regions": "Regionen",
    "unions": "Verbände",
    "existingRegions": "",
    "existingUnions": "",
    "homeUnion": "",
    "expiresOn": "Läuft ab am",
    "selectUser": "Benutzer auswählen",
    "none": "Keine(r)",
    "level": "Niveau",
    "actionAddAll": "Alle hinzufügen",
    "reason": "Grund",
    "gettingData": "Daten abrufen",
    "success": "Erfolg",
    "error": "Fehler",
    "actionEdit": "Bearbeiten",
    "actionSubmit": "Einreichen",
    "logo": "Logo",
    "actionDropFile": "Datei hier ablegen oder",
    "imageFormatsAllowed1": "JPG/PNG/GIF-Dateien zulässig",
    "forename": "Vorname",
    "lastname": "Nachname",
    "emailAddress": "E-Mail-Adresse",
    "email": "E-Mail",
    "validationIsRequired": "ist erforderlich",
    "code": "Code",
    "certified": "Zertifiziert",
    "certExpiry": "Ablauf Zertifikat",
    "session": "Einheit",
    "yes": "Ja",
    "no": "Nein",
    "actionDownload": "Herunterladen",
    "close": "Schließen",
    "user": "Benutzer",
    "location": "",
    "from": "",
    "to": "",
    "type": "",
    "validationError": "",
    "validationErrorDescription": "",
    "errorSaving": "",
    "saving": ""
  },
  "navigation": {
    "courses": "Kurse",
    "myCourses": "Meine Kurse",
    "courseAdmin": "Kurs-Admin",
    "training": "Schulung",
    "mySessions": "Meine Einheiten",
    "sessionAdmin": "Einheiten-Admin",
    "dashboard": "Dashboard",
    "navAbout": "Über",
    "navAdministration": "Administratoren",
    "navUnions": "Verbände",
    "logout": "Abmelden",
    "myProfile": "Mein Profil",
    "coursesAttended": "Absolvierte Kurse",
    "licensesAwarded": "Verliehene Lizenzen",
    "certification": "",
    "fileshare": "",
    "correspondence": ""
  },
  "mySessions": {
    "viewMyTrainingSession": "Meine Schulungseinheiten anzeigen",
    "hostTrainingSession": "Eine Schulungseinheit abhalten",
    "selectCourse": "Bitte einen Kurs auswählen",
    "selectUnion": "Bitte einen Verband auswählen",
    "selectLicense": "Bitte eine Lizenz auswählen",
    "licenseInfo": "Lizenzinformationen",
    "validIn": "Gültig in",
    "language": "Sprache",
    "unionToAdmin": "Zu verwaltender Verband"
  },
  "filters": {
    "filterByRegion": "Nach Region filtern",
    "filterByUnion": "Nach Verband filtern",
    "filterByStatus": "Nach Status filtern",
    "filterByQualityStatus": "Nach Qualitätsstatus filtern",
    "filterByLevel": "Nach Niveau filtern",
    "filterByStrand": "Nach Bereich filtern",
    "filterByExpiry": "Nach Ablauf filtern",
    "filterByDeliveryType": "Nach Art der Durchführung filtern",
    "pleaseSelectLevel": "Bitte ein Niveau auswählen",
    "pleaseSelectStrand": "Bitte einen Bereich auswählen",
    "pleaseSelectRegions": "Bitte Regionen auswählen",
    "pleaseSelectUnions": "Bitte Verbände auswählen",
    "pleaseSelectStatus": "Bitte einen Status auswählen",
    "filterByLanguage": "",
    "filterByCourse": "",
    "filterByAttendeeRegion": "",
    "filterByAttendeeUnion": "",
    "filterByUserEmail": "",
    "filterByAssessmentStatus": ""
  },
  "sessionAdmin": {
    "viewScheduledTrainingSession": "Geplante Schulungseinheiten anzeigen",
    "scheduleTrainingSession": "Schulungseinheit planen",
    "addTrainingSession": "Schulungseinheit hinzufügen"
  },
  "editTrainingSession": {
    "editTrainingSession": "Schulungseinheit bearbeiten",
    "scheduledTraining": "Geplante Schulung",
    "certificateLanguage": "Sprache des Zertifikats",
    "venueName": "Name des Veranstaltungsorts",
    "venueAddress": "Anschrift des Veranstaltungsorts",
    "organiser": "Organisator",
    "selectAnEducator": "Einen Kursleiter auswählen",
    "addEducator": "Kursleiter hinzufügen",
    "addNoteToHistory": "Hinweis zur Historie hinzufügen",
    "approve": "Genehmigen",
    "saveTrainingSession": "Schulungseinheit speichern",
    "viewTrainingSessionHistory": "Historie der Schulungseinheit anzeigen",
    "noHistory": "Keine Historie anzuzeigen",
    "addEducatorWarning": "Dieser Kursleiter kann unterstützen, aber die Einheit nicht leiten. Zur Leitung der Einheit ist mindestens ein Hauptkursleiter erforderlich.",
    "assistingEducators": "Unterstützende Kursleiter",
    "canLead": "Kann leiten?",
    "selectLanguage": "Bitte eine Sprache auswählen",
    "scheduleCourse": "Kurs planen",
    "saveCourse": "Kurs speichern",
    "addCourse": "Kurs hinzufügen",
    "emailLanguageTooltip": "E-Mails an die Teilnehmenden werden in dieser Sprache verfasst",
    "startTimeLocalTime": ""
  },
  "addNote": {
    "addNote": "Hinweis hinzufügen",
    "noteDetails": "Hinweisdetails"
  },
  "courseSessionStatus": {
    "awaitingApproval": "Auf Genehmigung warten",
    "rejected": "Abgelehnt",
    "approved": "Genehmigt"
  },
  "tabs": {
    "tabattendees": "Teilnehmende",
    "tabhistory": "Historie",
    "tabCourseMaterials": "Kursmaterialien"
  },
  "addCourseAttendees": {
    "titleViewTrainingSessionAttendees": "Teilnehmende der Schulungseinheit anzeigen",
    "editAttendeeList": "Teilnehmerliste bearbeiten",
    "attendeeName": "Name",
    "assessmentStatus": "Bewertungsstatus",
    "actions": "Aktionen",
    "editAttendees": "Teilnehmende bearbeiten",
    "actionConfirm": "Bestätigen",
    "noUsersFound": "Keine Benutzer gefunden",
    "attendeeUpdateError": "Fehler beim Aktualisieren der Teilnehmenden",
    "attendeeUpdateErrorDescription": "Beim Aktualisieren der Teilnehmerliste ist ein Fehler aufgetreten. Wurde eine E-Mail-Vorlage für das Gebietsschema der Einheit konfiguriert?",
    "optionSelectStatus": "Status auswählen",
    "actionUpdateStatus": "Status aktualisieren",
    "actionAdd": "Hinzufügen",
    "titleAddAssessment": "Bewertung hinzufügen",
    "selectLanguage": "Sprache auswählen",
    "actionLicense": "Lizenz",
    "actionLicenseDescription": "Diesem Teilnehmenden eine Lizenz verleihen",
    "actionEdit": "Bearbeiten",
    "unmetPrerequisites": "Unerfüllte Voraussetzungen",
    "approveAreYouSure": "Sind Sie sicher, dass Sie die Zertifizierung für die ausgewählten Teilnehmenden genehmigen möchten?",
    "approveCertification": "Zertifizierung genehmigen",
    "errorUpdatingStatusTitle": "Fehler bei der Statusaktualisierung",
    "errorUpdatingStatusMessage": "Bei der Aktualisierung des Teilnehmerstatus ist ein Fehler aufgetreten",
    "sendAttendeeInvites": "",
    "sendAttendeeInvitesToolTip": ""
  },
  "editAssessment": {
    "editAssessment": "Bewertung bearbeiten",
    "competency": "Kompetenz",
    "notYetCompetent": "Noch nicht kompetent",
    "notCompetent": "",
    "competent": "Kompetent",
    "excellent": "Exzellent",
    "criteria": "Kriterien",
    "commentHelpText": "Kommentieren – Obligatorisch für „Noch nicht kompetent“",
    "actionSaveAndPublish": "Speichern und veröffentlichen",
    "assessorComment": "Kommentar des Bewerters",
    "publishedBy": "Veröffentlicht von",
    "lastUpdatedBy": "Zuletzt aktualisiert von",
    "addedBy": "Hinzugefügt von",
    "errorSavingAssessment": "Fehler beim Speichern der Bewertung",
    "errorSavingAssessmentMessage": "Kontrollieren, ob alle Eingaben gültig waren",
    "confirmCancelAssessment": "Sind Sie sicher, dass Sie abbrechen möchten?",
    "leaveAssessment": "Bewertung verlassen?",
    "confirmPublishAssessment": "Sind Sie sicher, dass Sie diese Bewertung veröffentlichen möchten? Nach der Veröffentlichung kann die Bewertung nicht mehr bearbeitet werden.",
    "publishAssessment": "Bewertung veröffentlichen?",
    "viewAssessment": "Bewertung anzeigen",
    "attendeeSelfReflection": "Selbsteinschätzung des Teilnehmenden",
    "postCourseProgress": "",
    "postLiveExperienceProgress": "",
    "attendeeNotCompetentHeading": "",
    "attendeeMayBeCompetent": "",
    "attendeeNotCompetent": "",
    "attendeeNotCompetentConfirmation": "",
    "unpublishAssessmentConfirmation": ""
  },
  "feedback": {
    "viewFeedback": "Feedback anzeigen"
  },
  "activeCerts": {
    "activeCertificateStatistics": "Statistik der aktiven Zertifikate",
    "date1": "Datum 1",
    "date2": "Datum 2",
    "activeCerts": "Aktive Zertifikate",
    "pickDate": "Ein Datum auswählen",
    "total": "Insgesamt"
  },
  "issuedCerts": {
    "issuedCertificateStatistics": "Statistik der ausgestellten Zertifikate",
    "filterByDeliveryType": "Nach Art der Durchführung filtern",
    "range1": "Bereich 1",
    "range2": "Bereich 2",
    "dateTo": "Bis",
    "regionUserFilters": "Regions-/Benutzerfilter",
    "regionUserFiltersDescription1": "Regions- und Verbandsfilter werden abhängig von der Durchführungsart des Kurses unterschiedlich angewendet:",
    "regionUserFiltersDescription2": "Online & Scorm: Die Statistiken sind nach dem für den Teilnehmenden registrierten Verband geordnet",
    "regionUserFiltersDescription3": "Persönlich: Die Statistiken sind nach dem Verband geordnet, bei dem die Schulung stattfand",
    "courseCode": "Kurscode",
    "courseName": "Kursbezeichnung",
    "issuedCerts1": "Ausgestellte Zertifikate 1",
    "issuedCerts2": "Ausgestellte Zertifikate 2",
    "percentageIncrease": "% Zunahme",
    "range1Start": "Bereich 1 Start",
    "range1End": "Bereich 1 Ende",
    "range2Start": "Bereich 2 Start",
    "range2End": "Bereich 2 Ende"
  },
  "strands": {
    "strandRugbyReady": "Rugby Ready",
    "strandLaws": "Spielregeln",
    "strandStrengthAndConditioning": "Stärke und Konditionierung",
    "strandPlayerWelfare": "Wohlergehen der Spieler",
    "strandKeepRugbyClean": "Sauberes Rugby",
    "strandCoaching": "Training",
    "strandOfficiating": "Schiedsrichter",
    "strandIntegrity": "Integrität",
    "strandMatchDayStaff": "Unterstützendes Personal an Spieltagen",
    "strand": "Bereich"
  },
  "licenseStatus": {
    "licenseActive": "Aktiv",
    "licenseRevoked": "Widerrufen",
    "licenseExpired": "Abgelaufen",
    "licenseProvisional": "Provisorisch"
  },
  "licenses": {
    "licenses": "Lizenzen",
    "grantEducatorLicense": "Kursleiterlizenz erteilen",
    "actionRevoke": "Widerrufen",
    "grantALicense": "Eine Lizenz erteilen",
    "grantLicense": "Lizenz erteilen",
    "certificatesAwarded": "Verliehene Zertifikate",
    "actionGrantALicense": "Eine Lizenz erteilen",
    "licenseRevoke": "Lizenzwiderruf",
    "revokeLicense": "Lizenz widerrufen",
    "noRevokeEmailHeader": "Keine automatische Widerrufs-E-Mail",
    "noRevokeEmailBody": "Es wird keine automatische E-Mail versendet, wenn eine Lizenz widerrufen wird. Die Administratoren sollten diesen Vorgang ggf. persönlich kommunizieren.",
    "viewEligibleCourses": "Zulässige Kurse anzeigen",
    "eligibleCourses": "Zulässige Kurse",
    "messageLicenceAwardDelay": "Es gibt eine Verzögerung zwischen der Verleihung eines Zertifikats durch einen Manager an einen Teilnehmenden und der Ausstellung des Zertifikats an den Teilnehmenden. Die unten stehende Tabelle zeigt nur verliehene Zertifikate an, daher ist es möglich, dass ein kürzlich an einen Teilnehmenden verliehenes Zertifikat nicht in der unten stehenden Tabelle angezeigt wird.",
    "messageEducatorCanTeach": "Ein Kursleiter mit der vorgeschlagenen Lizenz kann folgende Kurse abhalten",
    "licenseAwardedTo": "Lizenz verliehen an",
    "errorAddingLicense": "Während des Hinzufügens dieser Lizenz ist ein Fehler aufgetreten",
    "gettingLicenseInformation": "Lizenzinformationen abrufen",
    "noLicenses": "Sie haben zur Zeit keine aktiven Lizenzen",
    "licenseHistory": "Lizenzhistorie",
    "qualityStatus": "Qualitätsstatus",
    "delayIssuingCertificate": "Hinweis: Verzögerung bei der Ausstellung von Zertifikaten",
    "delayIssuingCertificateInfo": "Es gibt eine Verzögerung zwischen der Verleihung eines Zertifikats durch einen Manager an einen Teilnehmenden und der Ausstellung des Zertifikats an den Teilnehmenden. Die unten stehende Tabelle zeigt nur verliehene Zertifikate an, daher ist es möglich, dass ein kürzlich an einen Teilnehmenden verliehenes Zertifikat nicht in der unten stehenden Tabelle angezeigt wird.",
    "noActiveLicenses": "Keine aktiven Lizenzen",
    "addLicenseError": "Während des Hinzufügens dieser Lizenz ist ein Fehler aufgetreten",
    "myLicenses": "Meine Lizenzen",
    "emailWorkforce": "Email workforce",
    "licenseAmended": "",
    "licenseAmendedConfirmationHeading": "",
    "licenseAmendConfirmation": "",
    "expireLicense": "",
    "amendLicense": ""
  },
  "regionAdmin": {
    "rtmUser": "RTM-Benutzer",
    "saveRegion": "Region speichern"
  },
  "unionAdmin": {
    "unionTrainingEducationAdministrator": "T&E-Administrator des Verbands",
    "canDeliverCoursesForWr": "Kann Kurse im Auftrag von World Rugby abhalten",
    "saveUnion": "Verband speichern",
    "gettingUnion": "Verband abrufen",
    "unionSaved": "Verband gespeichert"
  },
  "certStatus": {
    "certStatusInProgress": "Wird bearbeitet",
    "certStatusValid": "gültig",
    "certStatusInExpired": "Abgelaufen",
    "certStatusAll": "Alle"
  },
  "userAdmin": {
    "userList": "Benutzerliste",
    "inviteUser": "Benutzer einladen",
    "infoNoEmailInviteHeader": "Es wird keine Einladungs-E-Mail gesendet",
    "infoNoEmailInviteBody1": "Diese Funktion fügt einen Benutzer mit der Iris-Backend-Rolle zur Datenbank hinzu.",
    "infoNoEmailInviteBody2": "Bei dieser Funktion wird keine Einladungs-E-Mail an den Benutzer versendet, es liegt also in der Verantwortung des Iris-Administrators, den Benutzer darüber zu informieren, dass er hinzugefügt wurde.",
    "infoNoEmailInviteBody3": "Wenn der Benutzer sich noch nicht mithilfe der hier bereitgestellten E-Mail-Adresse bei WR SSO registriert hat, sollte er das tun.",
    "actionAddUserAndAssignBackendRole": " Benutzer hinzufügen und Backend-Rolle zuweisen",
    "irisUserAddedHeader": "Iris-Backend-Benutzer hinzugefügt",
    "irisUserAddedBody": "wurde als Iris-Backend-Benutzer hinzugefügt",
    "errorAddingUserHeader": "Fehler beim Hinzufügen des Backend-Benutzers",
    "errorAddingUserBody": "Beim Hinzufügen des Backend-Benutzers ist ein Fehler aufgetreten.",
    "isExactEmail": "Ist genaue E-Mail",
    "userBaseId": "Benutzerbasis-ID",
    "lastLoginDate": "letztes Anmeldedatum",
    "coursesAndCertificates": "Kurse und Zertifikate",
    "roles": "Rollen",
    "currentValidCertificates": "Aktuell gültige Zertifikate",
    "userLicenses": "Benutzerlizenzen",
    "userNeverGrantedLicense": "Diesem Benutzer wurde noch nie eine Lizenz erteilt",
    "infoRegionManagerheader": "Rolle Regionaler Schulungsleiter",
    "infoRegionManagerDetail1": "Die Rolle Regionaler Schulungsleiter kann im",
    "infoRegionManagerDetail2": "Formular Regionsverwaltung erteilt werden",
    "infoUnionManagerheader": "Rolle Verbandsschulungsleiter",
    "infoUnionManagerDetail1": "Die Rolle Verbandsschulungsleiter kann im",
    "infoUnionManagerDetail2": "Formular Verbandsverwaltung erteilt werden",
    "userRoles": "Benutzerrollen",
    "infoRolesUpdatedHeader": "Rollen aktualisiert",
    "infoRolesUpdatedBody": "Die Benutzerrollen wurden erfolgreich aktualisiert",
    "userExactEmail": "Genaue E-Mail verwenden",
    "select": "Auswählen",
    "noUsers": "Keine Benutzer",
    "searchAttendee": "Suche nach Teilnehmenden zum Hinzufügen"
  },
  "reports": {
    "reports": "Berichte",
    "report": "Bericht",
    "deliveryType": "Art der Durchführung",
    "actionGetReport": "Bericht abrufen",
    "regions": "Region(en)",
    "unions": "Verband/Verbände"
  },
  "qualityAudit": {
    "manage": "Verwalten",
    "qualityAssurance": "Qualitätssicherung",
    "qualityAudits": "Qualitätsprüfungen",
    "date": "Datum",
    "addQualityAudit": "Qualitätsprüfung hinzufügen",
    "noAuditsAdded": "Zu dieser Lizenz wurden keine Prüfungen hinzugefügt",
    "educatorCanTeach": "Ein Kursleiter mit der vorgeschlagenen Lizenz kann folgende Kurse abhalten:",
    "auditor": "Prüfer",
    "published": "Veröffentlicht",
    "actionPublish": "Veröffentlichen",
    "audits": "Prüfungen",
    "assignAuditor": "Prüfer zuweisen",
    "myAssignedQas": "Meine zugewiesenen QP",
    "audit": "Prüfung",
    "auditBy": "Prüfung von",
    "publishedBy": "Diese Prüfung wurde veröffentlicht von ",
    "onDate": "am",
    "venue": "Veranstaltungsort",
    "detailsPlaceholder": "",
    "areasOfStrength": "Stärken",
    "areasOfStrengthPlaceholder": "Hier Stärken hinzufügen",
    "areasForDevelopment": "Entwicklungsbereiche",
    "areasForDevelopmentPlaceholder": "Hier Entwicklungsbereiche hinzufügen",
    "agreedActionPlan": "Vereinbarter Aktionsplan",
    "agreedActionPlanPlaceholder": "Hier vereinbarten Aktionsplan hinzufügen",
    "educatorSelfReflection": "Selbsteinschätzung des Kursleiters",
    "educatorSelfReflectionPlaceholder": "Hier Selbsteinschätzung des Kursleiters hinzufügen",
    "grade": "Niveau",
    "gradePlaceholder": "Bitte ein Niveau auswählen",
    "behaviour": "Verhalten",
    "behaviourPlaceholder": "Bitte Verhalten auswählen",
    "saveAsDraft": "Als Entwurf speichern",
    "auditsOnEducator": "Prüfungen des Kursleiters",
    "auditsByEducator": "Prüfungen durch den Kursleiter",
    "educator": "Kursleiter",
    "addEducatorNote": "Kursleiterhinweise hinzufügen",
    "educatorNoteTooltip": "Die Kursleiterhinweise sind für Kursleiter und ihre Vorgesetzten im Reiter Kurshistorie sichtbar",
    "qaSubmitSuccess": "Qualitätsprüfung erfolgreich eingereicht",
    "qaPublishedSuccess": "Qualitätsprüfung erfolgreich veröffentlicht",
    "qaConfirmPublish": "Sind Sie sicher, dass Sie diese Qualitätsprüfung veröffentlichen möchten? Nach der Veröffentlichung kann die Prüfung nicht mehr bearbeitet werden.",
    "qaConfirmPublishTitle": "Prüfung veröffentlichen?",
    "qaLoadingText": "Informationen zur Qualitätsprüfung abrufen...",
    "manageAudits": "Prüfungen verwalten",
    "myActiveLicences": "Meine aktiven Lizenzen",
    "editQualityAudit": "Qualitätsprüfung bearbeiten",
    "submitForm": "Formular einreichen",
    "auditPublished": "Prüfung wurde veröffentlicht",
    "user": "Benutzer",
    "licenseHolder": "Lizenzinhaber",
    "licenseStatus": "",
    "publishStatus": "",
    "actionSubmitEducatorFeedback": "",
    "actionSubmitForEducatorFeedback": ""
  },
  "qualityAuditStatus": {
    "Draft": "",
    "AwaitingEducatorFeedback": "",
    "AwaitingAuditeeFeedback": "",
    "AwaitingPublish": "",
    "Published": ""
  },
  "termsAndConds": {
    "heading": "Die Allgemeinen Geschäftsbedingungen von World Rugby akzeptieren",
    "main": "Um Iris nutzen zu können, müssen Sie die folgenden Allgemeinen Geschäftsbedingungen akzeptieren:",
    "educatorCodeOfConductHeading": "Verhaltenskodex für Kursleiter",
    "educatorLinkDescription": "Der Verhaltenskodex für Kursleiter kann unter folgender Adresse eingesehen werden",
    "educatorAcceptCodeOfConduct": "Ich akzeptiere den Verhaltenskodex für Kursleiter"
  },
  "courseSessionAttendees": {
    "removeAttendees": "Teilnehmende entfernen",
    "addAttendees": "Teilnehmende hinzufügen",
    "addAttendeesBy": "Teilnehmende hinzufügen von",
    "draft": "Entwurf",
    "assessment": "Bewertung",
    "noAttendees": "Keine Teilnehmenden",
    "noAttendeesSubTitle": "Es wurden keine Teilnehmenden zu diesem Kurs hinzugefügt. Klicken Sie auf die Schaltfläche unten, um Teilnehmende hinzuzufügen.",
    "bulkAddAttendees": "Mehrere Teilnehmende per E-Mail-Adresse hinzufügen",
    "findExisting": "Einen bestehenden Benutzer finden",
    "registeringNew": "Einen neuen Benutzer anmelden",
    "bulkAddByEmailA": "Fügen Sie mehrere Teilnehmende zu diesem Kurs hinzu. Geben Sie die E-Mail-Adressen der Teilnehmenden in das Feld unten ein (eine E-Mail-Adresse pro Zeile) und klicken Sie auf die Schaltfläche „Teilnehmende hinzufügen“.",
    "bulkAddByEmailB": "Es werden nur bestehende Iris-Benutzer zum Kurs hinzugefügt. Eine Liste der E-Mail-Adressen der Teilnehmenden, die nicht hinzugefügt werden konnten, wird Ihnen zur Verfügung gestellt.",
    "addAttendeeLabel": "E-Mail-Adressen der Teilnehmenden (eine pro Zeile)",
    "addressesDoNotExist": "Diese E-Mail-Adressen der Teilnehmenden sind nicht in Iris vorhanden",
    "emailsAreRegistered": "Die folgenden E-Mail-Adressen sind als Benutzer angemeldet",
    "notPermittedToAccess": "",
    "emailsNotPermittedToAccess": "",
    "registerNewUser": "Melden Sie einen neuen Benutzer an und fügen Sie ihn als Teilnehmenden zu diesem Kurs hinzu, sofern der Benutzer noch nicht in Iris vorhanden ist.",
    "firstName": "Vorname",
    "surname": "Nachname",
    "noEmailWarning": "Verfügt der Teilnehmende nicht über eine E-Mail-Adresse, werden E-Mail-Benachrichtigungen an den organisierenden Kursleiter gesendet.",
    "registerNewUserAsAttendee": "Neuen Benutzer als Teilnehmenden anmelden",
    "draftAttendeeList": "Ihr Entwurf der Teilnehmerliste",
    "updateAttendeeList": "Sind Sie sicher, dass Sie die Teilnehmerliste aktualisieren möchten?",
    "confirmThisAction": "Durch Bestätigung dieser Aktion erhalten alle neu hinzugefügten Teilnehmenden eine Einführungs-E-Mail zu diesem Kurs.",
    "confirmTitle": "Teilnehmerliste aktualisieren?",
    "errorAddingUserMessage": "Beim Hinzufügen eines Benutzers ist ein Fehler aufgetreten.",
    "errorAddingAttendees": "Beim Hinzufügen von Teilnehmenden ist ein Fehler aufgetreten."
  },
  "courseSessionHost": {
    "attendeeNotes": "Teilnehmerhinweise",
    "attendeeNotesTooltip": "Diese Hinweise erscheinen in Einladungs-E-Mails an neue Teilnehmende (sobald sie in den E-Mail-Vorlagen konfiguriert wurden). Bitte verwenden Sie die ausgewählte Sprache.",
    "addCourse": "Kurs hinzufügen",
    "noEducatorLicensesDescription": "Sie haben keine aktiven Lizenzen. Sie benötigen eine aktive Kursleiterlizenz, um Kurse geben zu können. Bitte wenden Sie sich an Ihren regionalen Schulungsleiter, um eine Kursleiterlizenz zu erhalten.",
    "locationTooltip": ""
  },
  "courseSessionRouteTags": {
    "viewMyCourses": "Meine Kurse anzeigen",
    "viewScheduledCourses": "Geplante Kurse anzeigen",
    "hostCourse": "Einen Kurs abhalten",
    "scheduleCourse": "Kurs planen",
    "editCourse": "Kurs bearbeiten",
    "viewCourse": "Kurs anzeigen",
    "viewAttendees": "Teilnehmende anzeigen",
    "viewCourseHistory": "Kurshistorie anzeigen"
  },
  "routeTags": {
    "acceptEducatorTerms": "Die Allgemeinen Geschäftsbedingungen für Kursleiter akzeptieren"
  },
  "profileCourses": {
    "started": "Begonnen",
    "noCoursesAttended": "Keine Kurse absolviert",
    "notAttendedAnyCourses": "Sie haben keine Kurse absolviert",
    "validCertLookupAll": "Alle",
    "validCertLookupCurrent": "Aktuell gültige Zertifikate",
    "validCertLookupExpired": "Abgelaufen",
    "validCertLookupInProgress": "Wird bearbeitet"
  },
  "profileLicenses": {
    "noLicenses": "Keine Lizenzen",
    "noLicensesMatch": "Keine Lizenzen entsprechen den Kriterien",
    "expiry": "Ablauf"
  },
  "attendeeEmailInvite": {
    "showEmailPreview": "E-Mail-Vorschau der Einladung anzeigen",
    "attendeeEmailPreview": "E-Mail-Vorschau für Teilnehmende",
    "subject": "Betreff",
    "htmlContent": "HTML-Inhalt",
    "textContent": "Textinhalt"
  },
  "myDashboard": {
    "myActions": "Meine Aktionen",
    "recentCourseRequringApproval": "Aktuelle genehmigungspflichtige Kurse",
    "attendeesAwaitingCertApproval": "Teilnehmende, die auf Genehmigung der Zertifizierung warten",
    "underMyAdmin": "Unter meiner Verwaltung",
    "recentCourses": "Aktuelle Kurse",
    "upcomingCourses": "Kommende Kurse",
    "auditsInProgress": "Prüfungen in Bearbeitung",
    "myRecentCourses": "Meine aktuellen Kurse",
    "awaitingApproval": "Auf Genehmigung warten",
    "myUpcomingCourses": "Meine kommenden Kurse",
    "myQualityAudits": "Meine Qualitätsprüfungen",
    "myAuditsInProgress": "Meine Prüfungen in Bearbeitung",
    "myUpcomingAudits": "Meine kommenden Prüfungen",
    "recentUpcomingCourses": "Aktuelle und kommende Kurse",
    "noCoursesSubtitle": "Sie haben weder aktuelle Kurse noch kommende geplante Kurse. Klicken Sie auf die Schaltfläche unten, um einen Kurs abzuhalten.",
    "activeAudits": "Aktive Prüfungen",
    "noAuditsSubtitle": "Sie haben weder aktuelle Prüfungen noch kommende geplante Prüfungen.",
    "auditsRequiringMyFeedback": "",
    "assessmentPeriodDueToExpire": "",
    "assessmentsExpireInPastYear": "",
    "correspondence": "Correspondence"
  },
  "fileshare": {
    "courses": "",
    "fileshareCourseMaterials": ""
  },
  "correspondence": {
    "decline": "",
    "validateCorrespondence": "",
    "id": "",
    "emailScheduleType": "",
    "status": "",
    "targetedWorkforce": "Targeted workforce",
    "viewUsers": "",
    "hideUsers": "",
    "attachments": "",
    "approve": "",
    "validate": ""
  },
  "educatorsEmail": {
    "numberOfUser": "",
    "noUsersFound": "",
    "workforce": "Workforce",
    "filters": "Filters"
  },
  "compliance": {
    "compliance": "",
    "complianceCheckEdit": "",
    "complianceCheckAdd": "",
    "compliantOnDate": "",
    "complianceCheckSuccessfullySaved": "",
    "requiredCertificates": "",
    "usersToCheck": "",
    "confirmRemoveRequiredCertificate": "",
    "confirmRemoveUser": "",
    "errorAddingUsers": "",
    "noCertificates": ""
  },
  "educatorList": {
    "numberOfCoursesDelivered": "",
    "numberOfUnionsDeliveredIn": ""
  }
};
