

















































































































































































































































































































import { Component } from "vue-property-decorator";
import { RawLocation } from "vue-router";

import {
  DashboardModel,
  CourseSessionDashboardModel,
  AuditDashboardModel,
  CourseSessionStatus,
  CorrespondenceDashboardModel,
  QualityAuditCollectionModel,
} from "@/core/models";
import MyLicenses from "@/components/dashboard/MyLicenses.vue";
import { DashboardService } from "@/core/services";
import DashboardMetric from "./DashboardMetric.vue";
import AppVue from "@/apps/AppVue.vue";
import { AssessmentCriteriaStatus, CourseSessionAttendeeStatus, QualityAuditStatus } from "@/core/constants";

@Component({
  components: {
    DashboardMetric,
    MyLicenses,
  },
})
export default class MyDashboardSummary extends AppVue {
  model: DashboardModel = new DashboardModel();
  courseSessionModel: CourseSessionDashboardModel =
    new CourseSessionDashboardModel();
  auditModel: AuditDashboardModel = new AuditDashboardModel();
  correspondenceModel: CorrespondenceDashboardModel =
    new CorrespondenceDashboardModel();
  isLoadingCourseMetrics = false;
  isLoadingAuditMetrics = false;
  isLoadingCorrespondenceMetrics = false;

  async created() {
    this.model = await DashboardService.getDashboardData();

    this.isLoadingCourseMetrics = true;
    this.isLoadingAuditMetrics = true;
    this.isLoadingCorrespondenceMetrics = true;

    DashboardService.getCourseSessionDashboardData().then((data) => {
      this.courseSessionModel = data;
      this.isLoadingCourseMetrics = false;
    });

    DashboardService.getAuditDashboardData().then((data) => {
      this.auditModel = data;
      this.isLoadingAuditMetrics = false;
    });

    DashboardService.getCorrespondenceData()
      .then((data) => (this.correspondenceModel = data))
      .finally(() => (this.isLoadingCorrespondenceMetrics = false));
  }

  get showActions() {
    return (
      this.showAwaitingApprovalAction && this.showAwaitingCertificationAction
    );
  }

  get showAwaitingApprovalAction() {
    const actionValue = this.courseSessionModel.coursesToApproveInMyArea;

    return !!actionValue && actionValue > 0;
  }

  get showAwaitingCertificationAction() {
    const actionValue = this.courseSessionModel.attendeesToCertify;

    return !!actionValue && actionValue > 0;
  }

  getCoursesAwaitingApprovalRoute() {
    return this.getScheduledCoursesRoute(
      CourseSessionStatus.AwaitingApproval,
      this.getRecentDate()
    );
  }

  getCoursesAwaitingCertificationRoute() {
    return this.getScheduledCoursesRoute(
      CourseSessionStatus.RequestCertificateApproval
    );
  }

  getRecentCoursesAreaRoute() {
    return this.getScheduledCoursesRoute(
      null,
      this.getRecentDate(),
      this.todayAsDate()
    );
  }

  getUpcomingCoursesAreaRoute() {
    return this.getScheduledCoursesRoute(null, this.todayAsDate());
  }

  getScheduledCoursesRoute(
    status: CourseSessionStatus | null,
    from: Date | null = null,
    to: Date | null = null
  ) {
    return this.getCoursesListRoute(
      "scheduledCourseSessionList",
      status,
      from,
      to
    );
  }

  getMyRecentCoursesRoute() {
    return this.getMyCoursesRoute(
      null,
      this.getRecentDate(),
      this.todayAsDate()
    );
  }

  getMyUpcomingCoursesRoute() {
    return this.getMyCoursesRoute(null, this.todayAsDate());
  }

  getMyAwaitingApprovalCoursesRoute() {
    return this.getMyCoursesRoute(CourseSessionStatus.AwaitingApproval);
  }

  getMyCoursesRoute(
    status: CourseSessionStatus | null,
    from: Date | null = null,
    to: Date | null = null
  ) {
    return this.getCoursesListRoute("myCourseSessionList", status, from, to);
  }

  getCoursesListRoute(
    routeName: string,
    status: CourseSessionStatus | null,
    from: Date | null = null,
    to: Date | null = null,
    assessmentStatusId: number | null = null,
    sessionAttendeeStatusId: number | null = null,
  ) {
    const query = {} as any;
    if (status) {
      query.statusId = status;
    }

    if (from) {
      query.from = this.formatDate(from);
    }

    if (to) {
      query.to = this.formatDate(to);
    }

    if (!!assessmentStatusId) {
      query.assessmentStatusId = assessmentStatusId;
    }

    if (!!sessionAttendeeStatusId) {
      query.statusId = sessionAttendeeStatusId;
    }

    return {
      name: routeName,
      query,
    };
  }

  getMyNycAssessmentsRoute() {
    var fromDate = this.today()
      .add(-3, "y")
      .toDate();

    return this.getCoursesListRoute(
      "myCourseSessionList",
      null,
      fromDate,
      null,
      AssessmentCriteriaStatus.NotYetCompetent,
    );
  }

  getMyExpiredAssessmentsRoute() {
    var fromDate = this.today()
      .add(-3, "y")
      .toDate();

    return this.getCoursesListRoute(
      "myCourseSessionList",
      null,
      fromDate,
      null,
      null,
      CourseSessionAttendeeStatus.AssessmentPeriodExpired + 1000,
    );
  }

  getNycAssessmentsAreaRoute() {
    var fromDate = this.today()
      .add(-3, "y")
      .toDate();

    return this.getCoursesListRoute(
      "scheduledCourseSessionList",
      null,
      fromDate,
      null,
      AssessmentCriteriaStatus.NotYetCompetent,
    );
  }

  getExpiredAssessmentsAreaRoute() {
    var fromDate = this.today()
      .add(-3, "y")
      .toDate();

    return this.getCoursesListRoute(
      "scheduledCourseSessionList",
      null,
      fromDate,
      null,
      null,
      CourseSessionAttendeeStatus.AssessmentPeriodExpired + 1000,
    );
  }

  getMyUnpublishedAudits() {
    return this.getUnpublishedAudits("listAuditsToAuditor");
  }

  getManagerUnpublishedAudits() {
    return this.getUnpublishedAudits("listAuditsToManage");
  }

  getUnpublishedAudits(routeName: string) {
    const query = {
      isPublished: "false",
      to: this.formatDate(this.todayAsDate()),
    };

    return this.getAuditlistRoute(routeName, query);
  }

  getMyUpcomingAudits() {
    return this.getUpcomingAudits("listAuditsToAuditor");
  }

  getManagerUpcomingAudits() {
    return this.getUpcomingAudits("listAuditsToManage");
  }

  getUpcomingAudits(routeName: string) {
    const query = {
      from: this.formatDate(this.todayAsDate()),
    };

    return this.getAuditlistRoute("listAuditsToAuditor", query);
  }

  getAuditsRequiringFeedbackRoute() {
    const query = {
      status: QualityAuditStatus.AwaitingAuditeeFeedback as number,
    };

    return this.getAuditlistRoute("listAuditsToAuditor", query);
  }

  getCorrespondenceRoute() {
    return {
      name: "correspondenceList",
    };
  }

  getAuditlistRoute(routeName: string, query: any) {
    return {
      name: routeName,
      query,
    };
  }

  getRecentDate() {
    return this.today()
      .add(this.recentPeriodInWeeks() * -1, "w")
      .toDate();
  }

  getSubmitAuditRoute(model: QualityAuditCollectionModel): RawLocation {
    return {
      name: "submitQualityAudit",
      params: {
        qualityAuditId: String(model.id),
      },
    };
  }

}
