export default {
  "common": {
    "name": "姓名",
    "hello": "您好",
    "details": "详细信息",
    "created": "已创建",
    "ok": "确定",
    "cancel": "取消",
    "save": "保存",
    "unpublish": "",
    "id": "ID",
    "search": "搜索",
    "status": "状态",
    "view": "查看",
    "course": "课程",
    "union": "联盟",
    "noData": "无数据",
    "startDate": "开始日期",
    "startTime": "",
    "endDate": "结束日期",
    "notes": "备注",
    "educators": "教师",
    "forUser": "对于用户",
    "regions": "地区",
    "unions": "联盟",
    "existingRegions": "",
    "existingUnions": "",
    "homeUnion": "",
    "expiresOn": "失效日期",
    "selectUser": "选择用户",
    "none": "无",
    "level": "等级",
    "actionAddAll": "全部添加",
    "reason": "原因",
    "gettingData": "获取数据",
    "success": "成功",
    "error": "错误",
    "actionEdit": "编辑",
    "actionSubmit": "提交",
    "logo": "徽标",
    "actionDropFile": "将文件拖拽到这里或",
    "imageFormatsAllowed1": "允许 JPG/PNG/GIF 文件",
    "forename": "名",
    "lastname": "姓",
    "emailAddress": "电子邮件地址",
    "email": "电子邮件",
    "validationIsRequired": "必填",
    "code": "代码",
    "certified": "已认证",
    "certExpiry": "证书有效期",
    "session": "课程",
    "yes": "是",
    "no": "否",
    "actionDownload": "下载",
    "close": "关闭",
    "user": "用户",
    "location": "",
    "from": "",
    "to": "",
    "type": "",
    "validationError": "",
    "validationErrorDescription": "",
    "errorSaving": "",
    "saving": ""
  },
  "navigation": {
    "courses": "课程",
    "myCourses": "我的课程",
    "courseAdmin": "课程管理员",
    "training": "训练",
    "mySessions": "我的课程",
    "sessionAdmin": "课程管理员",
    "dashboard": "仪表板",
    "navAbout": "关于",
    "navAdministration": "管理",
    "navUnions": "联盟",
    "logout": "注销",
    "myProfile": "我的个人资料",
    "coursesAttended": "参加的课程",
    "licensesAwarded": "颁发的许可",
    "certification": "",
    "fileshare": "",
    "correspondence": ""
  },
  "mySessions": {
    "viewMyTrainingSession": "查看我的训练课程",
    "hostTrainingSession": "举办训练课程",
    "selectCourse": "请选择课程",
    "selectUnion": "请选择联盟",
    "selectLicense": "请选择许可",
    "licenseInfo": "许可信息",
    "validIn": "有效期为",
    "language": "语言",
    "unionToAdmin": "联盟管理"
  },
  "filters": {
    "filterByRegion": "按地区筛选",
    "filterByUnion": "按联盟筛选",
    "filterByStatus": "按状态筛选",
    "filterByQualityStatus": "按质量状态筛选",
    "filterByLevel": "按等级筛选",
    "filterByStrand": "按领域筛选",
    "filterByExpiry": "按有效期筛选",
    "filterByDeliveryType": "按授课类型筛选",
    "pleaseSelectLevel": "请选择等级",
    "pleaseSelectStrand": "请选择领域",
    "pleaseSelectRegions": "请选择地区",
    "pleaseSelectUnions": "请选择联盟",
    "pleaseSelectStatus": "请选择状态",
    "filterByLanguage": "",
    "filterByCourse": "",
    "filterByAttendeeRegion": "",
    "filterByAttendeeUnion": "",
    "filterByUserEmail": "",
    "filterByAssessmentStatus": ""
  },
  "sessionAdmin": {
    "viewScheduledTrainingSession": "查看已安排的训练课程",
    "scheduleTrainingSession": "安排训练课程",
    "addTrainingSession": "添加训练课程"
  },
  "editTrainingSession": {
    "editTrainingSession": "编辑训练课程",
    "scheduledTraining": "已安排的训练",
    "certificateLanguage": "证书语言",
    "venueName": "场地名称",
    "venueAddress": "场地地址",
    "organiser": "组织者",
    "selectAnEducator": "选择教师",
    "addEducator": "添加教师",
    "addNoteToHistory": "添加历史备注",
    "approve": "批准",
    "saveTrainingSession": "保存训练课程",
    "viewTrainingSessionHistory": "查看训练课程历史记录",
    "noHistory": "没有要显示的历史记录",
    "addEducatorWarning": "这位教师可以协助授课，但无法担任本课程的主讲人。每节课程至少需要一位主讲教师。",
    "assistingEducators": "助教",
    "canLead": "能否担任主讲？",
    "selectLanguage": "请选择语言",
    "scheduleCourse": "安排课程",
    "saveCourse": "保存课程",
    "addCourse": "添加课程",
    "emailLanguageTooltip": "发送给参与者的电子邮件是用该语言编写的",
    "startTimeLocalTime": ""
  },
  "addNote": {
    "addNote": "添加备注",
    "noteDetails": "备注详细信息"
  },
  "courseSessionStatus": {
    "awaitingApproval": "等待审批",
    "rejected": "已拒绝",
    "approved": "已批准"
  },
  "tabs": {
    "tabattendees": "参与者",
    "tabhistory": "历史记录",
    "tabCourseMaterials": "课程材料"
  },
  "addCourseAttendees": {
    "titleViewTrainingSessionAttendees": "查看训练课程参与者",
    "editAttendeeList": "编辑参与者名单",
    "attendeeName": "姓名",
    "assessmentStatus": "评估状态",
    "actions": "行动",
    "editAttendees": "编辑参与者",
    "actionConfirm": "确认",
    "noUsersFound": "未找到用户",
    "attendeeUpdateError": "参与者更新错误",
    "attendeeUpdateErrorDescription": "更新参与者名单时发生错误。是否为授课区域配置了电子邮件模板？",
    "optionSelectStatus": "选择状态",
    "actionUpdateStatus": "更新状态",
    "actionAdd": "添加",
    "titleAddAssessment": "添加评估",
    "selectLanguage": "选择语言",
    "actionLicense": "许可",
    "actionLicenseDescription": "向该参与者颁发许可",
    "actionEdit": "编辑",
    "unmetPrerequisites": "未满足的前提条件",
    "approveAreYouSure": "确定要为所选参与者批准认证吗",
    "approveCertification": "批准认证",
    "errorUpdatingStatusTitle": "更新状态时出错",
    "errorUpdatingStatusMessage": "更新参与者状态时出错",
    "sendAttendeeInvites": "",
    "sendAttendeeInvitesToolTip": ""
  },
  "editAssessment": {
    "editAssessment": "编辑评估",
    "competency": "能力",
    "notYetCompetent": "不合格",
    "notCompetent": "",
    "competent": "合格",
    "excellent": "优秀",
    "criteria": "标准",
    "commentHelpText": "意见 -“不合格”者必填",
    "actionSaveAndPublish": "保存并发布",
    "assessorComment": "评估员意见",
    "publishedBy": "发布者",
    "lastUpdatedBy": "最后更新者",
    "addedBy": "添加者",
    "errorSavingAssessment": "保存评估时出错",
    "errorSavingAssessmentMessage": "检查所有输入是否有效",
    "confirmCancelAssessment": "确定要取消吗？",
    "leaveAssessment": "退出评估？",
    "confirmPublishAssessment": "确定要发布此评估吗？评估一经发布，将无法编辑",
    "publishAssessment": "是否发布评估？",
    "viewAssessment": "查看评估",
    "attendeeSelfReflection": "参与者自我反思",
    "postCourseProgress": "",
    "postLiveExperienceProgress": "",
    "attendeeNotCompetentHeading": "",
    "attendeeMayBeCompetent": "",
    "attendeeNotCompetent": "",
    "attendeeNotCompetentConfirmation": "",
    "unpublishAssessmentConfirmation": ""
  },
  "feedback": {
    "viewFeedback": "查看反馈"
  },
  "activeCerts": {
    "activeCertificateStatistics": "有效证书统计数据",
    "date1": "日期 1",
    "date2": "日期 2",
    "activeCerts": "有效证书",
    "pickDate": "选择一个日期",
    "total": "总计"
  },
  "issuedCerts": {
    "issuedCertificateStatistics": "已签发证书统计数据",
    "filterByDeliveryType": "按授课类型筛选",
    "range1": "范围 1",
    "range2": "范围 2",
    "dateTo": "至",
    "regionUserFilters": "地区/用户筛选器",
    "regionUserFiltersDescription1": "系统将根据授课类型以不同方式应用地区和联盟筛选器：",
    "regionUserFiltersDescription2": "在线和 Scorm：统计数据将按照参与者注册的联盟进行分组",
    "regionUserFiltersDescription3": "面对面：统计数据将按照训练所在的联盟进行分组",
    "courseCode": "课程代码",
    "courseName": "课程名称",
    "issuedCerts1": "已签发的证书 1",
    "issuedCerts2": "已签发的证书 2",
    "percentageIncrease": "增长 %",
    "range1Start": "范围 1 开始",
    "range1End": "范围 1 结束",
    "range2Start": "范围 2 开始",
    "range2End": "范围 2 结束"
  },
  "strands": {
    "strandRugbyReady": "Rugby Ready",
    "strandLaws": "比赛规则",
    "strandStrengthAndConditioning": "力量和体能训练",
    "strandPlayerWelfare": "球员福利",
    "strandKeepRugbyClean": "珍爱橄榄球运动，远离兴奋剂",
    "strandCoaching": "教练工作",
    "strandOfficiating": "裁判",
    "strandIntegrity": "正直",
    "strandMatchDayStaff": "比赛日人员",
    "strand": "领域"
  },
  "licenseStatus": {
    "licenseActive": "有效",
    "licenseRevoked": "已撤销",
    "licenseExpired": "已过期",
    "licenseProvisional": "临时"
  },
  "licenses": {
    "licenses": "许可",
    "grantEducatorLicense": "授予教师许可",
    "actionRevoke": "撤销",
    "grantALicense": "授予许可",
    "grantLicense": "授予许可",
    "certificatesAwarded": "已颁发证书",
    "actionGrantALicense": "授予许可",
    "licenseRevoke": "许可撤销",
    "revokeLicense": "撤销许可",
    "noRevokeEmailHeader": "无自动撤销电子邮件",
    "noRevokeEmailBody": "撤销许可时不会自动发送电子邮件。管理员可能希望亲自传达这一行动",
    "viewEligibleCourses": "查看合格课程",
    "eligibleCourses": "合格课程",
    "messageLicenceAwardDelay": "在经理向参与者颁发证书以及将证书颁发给参与者之间存在一定的延迟。下表只显示已颁发的证书，因此最近颁发给参与者的证书有可能不会显示在下表中",
    "messageEducatorCanTeach": "持有拟议许可的教师可以教授以下课程",
    "licenseAwardedTo": "将许可颁发给",
    "errorAddingLicense": "添加此许可时发生错误",
    "gettingLicenseInformation": "获取许可信息",
    "noLicenses": "您目前没有任何有效许可",
    "licenseHistory": "许可历史记录",
    "qualityStatus": "质量状态",
    "delayIssuingCertificate": "备注：证书颁发存在延迟",
    "delayIssuingCertificateInfo": "在经理向参与者颁发证书以及将证书颁发给参与者之间存在一定的延迟。下表只显示已颁发的证书，因此最近颁发给参与者的证书有可能不会显示在下表中",
    "noActiveLicenses": "没有有效许可",
    "addLicenseError": "添加此许可时发生错误",
    "myLicenses": "我的许可",
    "emailWorkforce": "Email workforce",
    "licenseAmended": "",
    "licenseAmendedConfirmationHeading": "",
    "licenseAmendConfirmation": "",
    "expireLicense": "",
    "amendLicense": ""
  },
  "regionAdmin": {
    "rtmUser": "RTM 用户",
    "saveRegion": "保存地区"
  },
  "unionAdmin": {
    "unionTrainingEducationAdministrator": "联盟 T&E 管理员",
    "canDeliverCoursesForWr": "可代表 World Rugby 授课",
    "saveUnion": "保存联盟",
    "gettingUnion": "获取联盟",
    "unionSaved": "联盟已保存"
  },
  "certStatus": {
    "certStatusInProgress": "进行中",
    "certStatusValid": "有效",
    "certStatusInExpired": "已过期",
    "certStatusAll": "全部"
  },
  "userAdmin": {
    "userList": "用户名单",
    "inviteUser": "邀请用户",
    "infoNoEmailInviteHeader": "不会发送邀请电子邮件",
    "infoNoEmailInviteBody1": "此功能使用 Iris 后端角色将用户添加到数据库",
    "infoNoEmailInviteBody2": "此功能不会向用户发送邀请电子邮件，因此应由 Iris 管理员负责通知用户其已经被添加",
    "infoNoEmailInviteBody3": "如果用户尚未执行此操作，则应使用此处提供的电子邮件地址注册 WR SSO",
    "actionAddUserAndAssignBackendRole": " 添加用户并指定后端角色",
    "irisUserAddedHeader": "已添加 Iris 后端用户",
    "irisUserAddedBody": "已被添加为 Iris 后端用户",
    "errorAddingUserHeader": "添加后端用户时出错",
    "errorAddingUserBody": "添加后端用户时发生错误",
    "isExactEmail": "是正确的电子邮件",
    "userBaseId": "用户群 ID",
    "lastLoginDate": "上次登录日期",
    "coursesAndCertificates": "课程和证书",
    "roles": "角色",
    "currentValidCertificates": "当前有效证书",
    "userLicenses": "用户许可",
    "userNeverGrantedLicense": "该用户始终未被授予许可",
    "infoRegionManagerheader": "区域训练经理角色",
    "infoRegionManagerDetail1": "可在地区管理表中授予区域训练经理",
    "infoRegionManagerDetail2": "角色",
    "infoUnionManagerheader": "联盟训练经理角色",
    "infoUnionManagerDetail1": "可在联盟管理表中授予联盟训练经理",
    "infoUnionManagerDetail2": "角色",
    "userRoles": "用户角色",
    "infoRolesUpdatedHeader": "角色已更新",
    "infoRolesUpdatedBody": "用户角色已成功更新",
    "userExactEmail": "使用正确的电子邮件",
    "select": "选择",
    "noUsers": "无用户",
    "searchAttendee": "搜索要添加的参与者"
  },
  "reports": {
    "reports": "报告",
    "report": "报告",
    "deliveryType": "授课类型",
    "actionGetReport": "获取报告",
    "regions": "地区",
    "unions": "联盟"
  },
  "qualityAudit": {
    "manage": "管理",
    "qualityAssurance": "质量保证",
    "qualityAudits": "质量审核",
    "date": "日期",
    "addQualityAudit": "添加质量审核",
    "noAuditsAdded": "未向此许可添加审核",
    "educatorCanTeach": "持有拟议许可的教师可以教授以下课程：",
    "auditor": "审核员",
    "published": "已发布",
    "actionPublish": "发布",
    "audits": "审核",
    "assignAuditor": "指定审核员",
    "myAssignedQas": "我指定的质量审核",
    "audit": "审核",
    "auditBy": "审核人",
    "publishedBy": "本次审核的发布者 ",
    "onDate": "时间",
    "venue": "场地",
    "detailsPlaceholder": "",
    "areasOfStrength": "优势",
    "areasOfStrengthPlaceholder": "在此处添加优势",
    "areasForDevelopment": "不足",
    "areasForDevelopmentPlaceholder": "在此处添加不足",
    "agreedActionPlan": "商定的行动计划",
    "agreedActionPlanPlaceholder": "在此处添加商定的行动计划",
    "educatorSelfReflection": "教师自我反思",
    "educatorSelfReflectionPlaceholder": "在此处添加教师自我反思",
    "grade": "年级",
    "gradePlaceholder": "请选择年级",
    "behaviour": "行为",
    "behaviourPlaceholder": "请选择行为",
    "saveAsDraft": "保存为草稿",
    "auditsOnEducator": "对教师进行审核",
    "auditsByEducator": "由教师进行审核",
    "educator": "教师",
    "addEducatorNote": "添加教师备注",
    "educatorNoteTooltip": "教师及其经理将在“课程历史记录”选项卡中看到教师备注",
    "qaSubmitSuccess": "质量审核提交成功",
    "qaPublishedSuccess": "质量审核发布成功",
    "qaConfirmPublish": "确定要发布此质量审核吗？审核一经发布将变为只读状态。",
    "qaConfirmPublishTitle": "是否发布审核？",
    "qaLoadingText": "获取质量审核信息...",
    "manageAudits": "管理审核",
    "myActiveLicences": "我的有效许可",
    "editQualityAudit": "编辑质量审核",
    "submitForm": "提交表单",
    "auditPublished": "审核已发布",
    "user": "用户",
    "licenseHolder": "许可持有人",
    "licenseStatus": "",
    "publishStatus": "",
    "actionSubmitEducatorFeedback": "",
    "actionSubmitForEducatorFeedback": ""
  },
  "qualityAuditStatus": {
    "Draft": "",
    "AwaitingEducatorFeedback": "",
    "AwaitingAuditeeFeedback": "",
    "AwaitingPublish": "",
    "Published": ""
  },
  "termsAndConds": {
    "heading": "接受 World Rugby 条款和条件",
    "main": "为了继续使用 Iris，您必须接受以下条款和条件：",
    "educatorCodeOfConductHeading": "教师行为准则",
    "educatorLinkDescription": "可以在如下位置查看教师行为准则",
    "educatorAcceptCodeOfConduct": "我接受教师行为准则"
  },
  "courseSessionAttendees": {
    "removeAttendees": "移除参与者",
    "addAttendees": "添加参与者",
    "addAttendeesBy": "添加参与者的方式",
    "draft": "草稿",
    "assessment": "评估",
    "noAttendees": "没有参与者",
    "noAttendeesSubTitle": "未向此课程添加任何参与者。单击下方按钮添加参与者",
    "bulkAddAttendees": "通过电子邮件地址批量添加参与者",
    "findExisting": "寻找现有用户",
    "registeringNew": "注册新用户",
    "bulkAddByEmailA": "向本课程添加多个参与者。在下方字段中输入参与者电子邮件地址，每行输入一个电子邮件地址，然后单击“添加参与者”按钮。",
    "bulkAddByEmailB": "只有现有 Iris 用户才会被添加到课程中。系统将为您提供无法添加的参与者电子邮件地址清单。",
    "addAttendeeLabel": "参与者电子邮件地址（每行一个）",
    "addressesDoNotExist": "Iris 中不存在参与者电子邮件地址",
    "emailsAreRegistered": "以下电子邮件地址已注册为用户",
    "notPermittedToAccess": "",
    "emailsNotPermittedToAccess": "",
    "registerNewUser": "注册一个新用户并将其添加为此课程的参与者。只有当 Iris 中不存在用户时才能执行此操作。",
    "firstName": "名字",
    "surname": "姓氏",
    "noEmailWarning": "如果参与者没有电子邮件地址，系统会将电子邮件通知发送给组织教师",
    "registerNewUserAsAttendee": "将新用户注册为参与者",
    "draftAttendeeList": "您的参与者名单草稿",
    "updateAttendeeList": "确定要更新参与者名单吗？",
    "confirmThisAction": "确认此操作后，所有新添加的参与者都将收到有关此课程的介绍电子邮件。",
    "confirmTitle": "是否更新参与者名单？",
    "errorAddingUserMessage": "添加用户时发生错误",
    "errorAddingAttendees": "添加参与者时发生错误"
  },
  "courseSessionHost": {
    "attendeeNotes": "参与者备注",
    "attendeeNotesTooltip": "这些备注将显示在发送给新参与者的邀请电子邮件中（在电子邮件模板中进行配置后）。请用所选语言编写",
    "addCourse": "添加课程",
    "noEducatorLicensesDescription": "您没有任何有效许可。您需要有效的教师许可才能教授课程。请联系您的区域训练经理，以获得教师许可授权",
    "locationTooltip": ""
  },
  "courseSessionRouteTags": {
    "viewMyCourses": "查看我的课程",
    "viewScheduledCourses": "查看已安排的课程",
    "hostCourse": "举办课程",
    "scheduleCourse": "安排课程",
    "editCourse": "编辑课程",
    "viewCourse": "查看课程",
    "viewAttendees": "查看参与者",
    "viewCourseHistory": "查看课程历史记录"
  },
  "routeTags": {
    "acceptEducatorTerms": "接受教师条款和条件"
  },
  "profileCourses": {
    "started": "已开始",
    "noCoursesAttended": "没有参加课程",
    "notAttendedAnyCourses": "您还没有参加任何课程",
    "validCertLookupAll": "全部",
    "validCertLookupCurrent": "当前有效证书",
    "validCertLookupExpired": "已过期",
    "validCertLookupInProgress": "进行中"
  },
  "profileLicenses": {
    "noLicenses": "没有许可",
    "noLicensesMatch": "没有符合条件的许可",
    "expiry": "到期"
  },
  "attendeeEmailInvite": {
    "showEmailPreview": "显示邀请电子邮件预览",
    "attendeeEmailPreview": "参与者电子邮件预览",
    "subject": "主题",
    "htmlContent": "HTML 内容",
    "textContent": "文本内容"
  },
  "myDashboard": {
    "myActions": "我的操作",
    "recentCourseRequringApproval": "最近需要审批的课程",
    "attendeesAwaitingCertApproval": "等待认证审批的参与者",
    "underMyAdmin": "在我的管理下",
    "recentCourses": "近期课程",
    "upcomingCourses": "即将开始的课程",
    "auditsInProgress": "正在进行审核",
    "myRecentCourses": "我的近期课程",
    "awaitingApproval": "等待审批",
    "myUpcomingCourses": "我即将开始的课程",
    "myQualityAudits": "我的质量审核",
    "myAuditsInProgress": "我正在进行的审核",
    "myUpcomingAudits": "我即将进行的审核",
    "recentUpcomingCourses": "近期和即将开始的课程",
    "noCoursesSubtitle": "您没有近期课程，也没有安排即将开始的课程。单击下方按钮举办课程",
    "activeAudits": "有效审核",
    "noAuditsSubtitle": "您没有近期审核，也没有安排即将进行的审核。",
    "auditsRequiringMyFeedback": "",
    "assessmentPeriodDueToExpire": "",
    "assessmentsExpireInPastYear": "",
    "correspondence": "Correspondence"
  },
  "fileshare": {
    "courses": "",
    "fileshareCourseMaterials": ""
  },
  "correspondence": {
    "decline": "",
    "validateCorrespondence": "",
    "id": "",
    "emailScheduleType": "",
    "status": "",
    "targetedWorkforce": "Targeted workforce",
    "viewUsers": "",
    "hideUsers": "",
    "attachments": "",
    "approve": "",
    "validate": ""
  },
  "educatorsEmail": {
    "numberOfUser": "",
    "noUsersFound": "",
    "workforce": "Workforce",
    "filters": "Filters"
  },
  "compliance": {
    "compliance": "",
    "complianceCheckEdit": "",
    "complianceCheckAdd": "",
    "compliantOnDate": "",
    "complianceCheckSuccessfullySaved": "",
    "requiredCertificates": "",
    "usersToCheck": "",
    "confirmRemoveRequiredCertificate": "",
    "confirmRemoveUser": "",
    "errorAddingUsers": "",
    "noCertificates": ""
  },
  "educatorList": {
    "numberOfCoursesDelivered": "",
    "numberOfUnionsDeliveredIn": ""
  }
};
