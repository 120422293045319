import { RoleName } from "@/core/constants";

export const licenseRoutes = [
  {
    path: "/licenses",
    name: "licenseList",
    meta: {
      title: "Licenses",
      tag: "licenses.licenses",
      localise: true,
      faceTofaceMode: true,
      },
    component: () =>
      import(/* webpackChunkName: "license" */ "@/views/license/LicenseList.vue"),
  },
  {
    path: "/licenses/new/:userId",
    name: "licenseNew",
    meta: {
      title: "Grant a license",
      tag: "licenses.grantALicense",
      localise: true,
      faceTofaceMode: true,
      roles: `${RoleName.RegionalTrainingManager},` +
        `${RoleName.GlobalTrainingManager},` +
        `${RoleName.IrisAdministrator}` },
    component: () =>
      import(/* webpackChunkName: "license" */ "@/views/license/LicenseForm.vue"),
  },
  {
    path: "/licenses/:licenseId",
    name: "licenseView",
    meta: {
      title: "License View",
      faceTofaceMode: true,
    },
    component: () =>
      import(/* webpackChunkName: "license" */ "@/views/license/LicenseForm.vue"),
  },
  {
    path: "/licenses/:licenseId/expire",
    name: "licenseExpire",
    meta: {
      title: "Expire License",
      faceTofaceMode: true,
      roles: `${RoleName.GlobalTrainingManager},` +
        `${RoleName.IrisAdministrator},` +
        `${RoleName.RegionalTrainingManager}` },
    component: () =>
      import(/* webpackChunkName: "license" */ "@/views/license/LicenseExpireForm.vue"),
  },
  {
    path: "/licenses/:licenseId/revoke",
    name: "licenseRevoke",
    meta: {
      title: "Revoke License",
      faceTofaceMode: true,
      roles: `${RoleName.RegionalTrainingManager},` +
        `${RoleName.GlobalTrainingManager},` +
        `${RoleName.IrisAdministrator}` },
    component: () =>
      import(/* webpackChunkName: "license" */ "@/views/license/LicenseRevokeForm.vue"),
  },
  {
    path: "/licenses/:licenseId/amend",
    name: "licenseAmend",
    meta: {
      title: "Amend License",
      faceTofaceMode: true,
      roles: `${RoleName.RegionalTrainingManager},` +
        `${RoleName.GlobalTrainingManager},` +
        `${RoleName.IrisAdministrator}` },
    component: () =>
      import(/* webpackChunkName: "license" */ "@/views/license/LicenseAmend.vue"),
  },
  {
    path: "/licenses/:licenseId/audit/create",
    name: "createQualityAudit",
    meta: {
      title: "Create Quality Audit",
      faceTofaceMode: true,
      roles: `${RoleName.RegionalTrainingManager},` +
        `${RoleName.GlobalTrainingManager},` +
        `${RoleName.IrisAdministrator}` +
        `${RoleName.UnionTrainingManager}`  +
        `${RoleName.UnionAdministrator}` },
    component: () =>
      import(/* webpackChunkName: "license" */ "@/views/license/QualityAuditForm.vue"),
  },
  {
    path: "/licenses/:licenseId/audit/edit/:qualityAuditId",
    name: "editQualityAudit",
    meta: {
      title: "Edit Quality Audit",
      tag: "qualityAudit.editQualityAudit",
      localise: true,
      faceTofaceMode: true,
      roles: `${RoleName.RegionalTrainingManager},` +
        `${RoleName.GlobalTrainingManager},` +
        `${RoleName.IrisAdministrator},` +
        `${RoleName.UnionTrainingManager},`  +
        `${RoleName.UnionAdministrator}` },
    component: () =>
      import(/* webpackChunkName: "license" */ "@/views/license/QualityAuditForm.vue"),
  },
  {
    path: "/audits/submit/:qualityAuditId",
    name: "submitQualityAudit",
    meta: {
      title: "Edit Quality Audit",
      tag: "qualityAudit.editQualityAudit",
      localise: true,
      faceTofaceMode: true,
    },
    component: () =>
      import(/* webpackChunkName: "license" */ "@/views/license/QualityAuditSubmitForm.vue"),
  },
  {
    path: "/audits/manage",
    name: "listAuditsToManage",
    meta: {
      title: "Audit list (Manager)",
      faceTofaceMode: true,
      roles: `${RoleName.RegionalTrainingManager},` +
        `${RoleName.GlobalTrainingManager},` +
        `${RoleName.IrisAdministrator},` +
        `${RoleName.UnionTrainingManager},`  +
        `${RoleName.UnionAdministrator}` },
    component: () =>
      import(/* webpackChunkName: "license" */ "@/views/license/ManagerAuditList.vue"),
  },
  {
    path: "/audits/audit",
    name: "listAuditsToAuditor",
    meta: {
      title: "Audit list (Auditor)",
      localise: true,
      faceTofaceMode: true,
    },
    component: () =>
      import(/* webpackChunkName: "license" */ "@/views/license/AuditorAuditList.vue"),
  },
];
